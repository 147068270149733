import React from 'react';
import { Trans } from '@lingui/macro';
import { Select, Space, Typography } from 'antd';
import useUpdateInterval from 'feature.home/hooks/useUpdateInterval';

type LocalProps = {
  reloadData: () => Promise<void>;
};

const Refresh = (props: LocalProps) => {
  const { reloadData } = props;

  const { onUpdateInterval, updateInterval } = useUpdateInterval(reloadData);

  const [loading, setLoading] = React.useState(false);

  return (
    <Space>
      <Typography.Text>Refresh</Typography.Text>
      <Select
        id="update-data"
        value={updateInterval}
        onChange={async (int) => {
          setLoading(true);
          await onUpdateInterval(int);
          setLoading(false);
        }}
        style={{ minWidth: 200 }}
        loading={loading}
      >
        <Select.Option value="manual">
          <Trans>manually</Trans>
        </Select.Option>
        <Select.Option value="every-15-minute">
          <Trans>update every 15 minutes</Trans>
        </Select.Option>
        <Select.Option value="every-5-minute">
          <Trans>update every 5 minutes</Trans>
        </Select.Option>
        <Select.Option value="every-1-minute">
          <Trans>update every minute</Trans>
        </Select.Option>
        <Select.Option value="every-30-sec">
          <Trans>update every 30 sec</Trans>
        </Select.Option>
        <Select.Option value="realTime">
          <Trans>Real-Time</Trans>
        </Select.Option>
      </Select>
    </Space>
  );
};

export default Refresh;
