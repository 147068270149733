import React from 'react';
import { Input, InputRef, Tooltip } from 'antd';
import { Button, DatePicker, Space } from 'antd';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import type { ProColumns } from '@ant-design/pro-components';
import moment from 'moment';
import { t, Trans } from '@lingui/macro';

import type { Row, QueueRecordState } from 'types';

type ColumnFilters = {
  state?: QueueRecordState | '',
  [key: string]: string | string[],
};

const getDate = (initialDate: string) => {
  if (initialDate) return moment(initialDate);
  return moment();
};

const getDateColumnProps = (dataIndex: string): ProColumns<Row> => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Space style={{ alignItems: 'stretch' }}>
        <DatePicker.RangePicker
          format={'DD-MM-YY'}
          onChange={(e) => {
            if (!e) return;
            setSelectedKeys([e[0].toISOString(), e[1].toISOString()]);
          }}
          value={[getDate(selectedKeys[0] as any), getDate(selectedKeys[1] as any)]}
          allowClear={false}
        />
        <Button
          onClick={() => {
            setSelectedKeys([moment().toISOString(), moment().toISOString()]);
            clearFilters();
            confirm({ closeDropdown: true });
          }}
          size="small"
          style={{ width: 90, height: '100%' }}
        >
          <Trans>Clear</Trans>
        </Button>
        <Button
          type="primary"
          onClick={() => {
            confirm({ closeDropdown: true });
          }}
          size="small"
          style={{ width: 90, height: '100%' }}
        >
          <Trans>OK</Trans>
        </Button>
      </Space>
    </div>
  ),
});

const getTextColumnProps = (dataIndex: string): ProColumns<Row> => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Space style={{ alignItems: 'stretch' }}>
        <Input
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={(e) => {
            confirm({ closeDropdown: true });
            e.stopPropagation()
          }}
        />
        <Button
          onClick={() => {
            setSelectedKeys([]);
            clearFilters();
            confirm({ closeDropdown: true });
          }}
          size="small"
          style={{ width: 90, height: '100%' }}
        >
          <Trans>Clear</Trans>
        </Button>
        <Button
          type="primary"
          onClick={() => {
            confirm({ closeDropdown: true });
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, height: '100%' }}
        >
          <Trans>OK</Trans>
        </Button>
      </Space>
    </div>
  ),
});

const ClearFilters = (props: {
  columnFilters?: ColumnFilters;
  ordering: React.MutableRefObject<{ ordering?: string | undefined }>;
  search: React.MutableRefObject<InputRef>;
  onClearFilters: () => void;
}) => {
  const { columnFilters, onClearFilters, ordering, search } = props;

  const onClear = () => {
    ordering.current = {};
    search.current.input.value = '';
    onClearFilters();
  };

  const relevantFilters = { ...columnFilters }
  delete relevantFilters['page'];

  // state is being handled separately
  const hasStateFilter = !!columnFilters.state;
  delete relevantFilters['state'];

  const noActiveFilters =
    !Object.keys(relevantFilters).length &&
    !ordering.current.ordering &&
    !search.current?.input?.value &&
    !hasStateFilter;

  if (noActiveFilters) return null;

  return (
    <Tooltip title={t`Clear all filters`}>
      <div className="ant-pro-table-list-toolbar-setting-item" onClick={onClear}>
        <FilterOutlined />
      </div>
    </Tooltip>
  );
};

export { getDateColumnProps, getTextColumnProps, ClearFilters };
export type { ColumnFilters };
