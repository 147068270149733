import styled from 'styled-components';
import { theme } from 'shared/theme';

export const PageWrapper = styled.div`
    background: ${theme.colors.primary.text};
    width: 100%;
    height: 100%;
`;

export const PageHeaderWrapper = styled.div`
  padding: 24px 24px 0;
  
  h1 {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  
  .ant-divider-horizontal {
    margin: 12px 0;
  }
`;