import { SettingsWrapper } from './style';

function ChartSettings({children}){
  return (
    <SettingsWrapper>
      {children}
    </SettingsWrapper>
  )
}

export default ChartSettings