import * as React from 'react';
import * as qs from 'query-string';

import { CLEAR_CONTEXT, SET_ORGANIZATION, SET_PROJECT, SET_STATE } from './actions';
import { reducer } from './reducer';
import { TGlobalFiltersAPICtx, TGlobalFiltersCtx } from './types';

export const initialState = {
  project: null,
  organization: null
};

const GlobalFiltersContext = React.createContext<TGlobalFiltersCtx>({} as TGlobalFiltersCtx);
const GlobalFiltersAPIContext = React.createContext<TGlobalFiltersAPICtx>(
  {} as TGlobalFiltersAPICtx
);

export const GlobalFiltersProvider = ({ children }) => {
  const searchParamsOrg = qs.parse(window.location.search).organization;
  const searchParamsPro = qs.parse(window.location.search).project;
  const defaultOrganization = searchParamsOrg || '';
  const defaultProject = searchParamsPro || '';

  const [state, dispatch] = React.useReducer(reducer, {
    project: defaultProject,
    organization: defaultOrganization,
  } as TGlobalFiltersCtx);

  const fns = React.useMemo(
    () => ({
      setProject: (s: string) => {
        dispatch({
          type: SET_PROJECT,
          payload: s,
        });
      },
      setOrganization: (s: string) => {
        dispatch({
          type: SET_ORGANIZATION,
          payload: s,
        });
      },
      setState: ({ org, pro }: { org: string; pro: string }) => {
        dispatch({
          type: SET_STATE,
          payload: { org, pro },
        });
      },
      clearContext: () => {
        dispatch({
          type: CLEAR_CONTEXT
        });
      }
    }),
    []
  );

  return (
    <GlobalFiltersContext.Provider
      value={{
        ...state,
      }}
    >
      <GlobalFiltersAPIContext.Provider
        value={{
          setProject: fns.setProject,
          setOrganization: fns.setOrganization,
          setState: fns.setState,
          clearContext: fns.clearContext
        }}
      >
        {children}
      </GlobalFiltersAPIContext.Provider>
    </GlobalFiltersContext.Provider>
  );
};

export const useGlobalFiltersCtx = () => {
  return React.useContext(GlobalFiltersContext);
};

export const useGlobalFiltersAPICtx = () => {
  return React.useContext(GlobalFiltersAPIContext);
};
