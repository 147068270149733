import { cs, enUS, sk } from 'date-fns/locale';
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  startOfWeek,
  subMonths,
} from 'date-fns';

export const dateFnsLocales = {
  en: enUS,
  cs: cs,
  sk: sk
}

interface GetMonthParams {
  locale: Locale,
  dateFormat?: string
  start?: Date,
  end?: Date
}

export const getMonths = ({locale, dateFormat = 'MMM', start = null, end = null}: GetMonthParams): string[] => {
  const now: Date = endOfMonth(new Date());
  const months: string[] = [];
  if(!start) start = subMonths(now, 11);
  if(!end) end = now;
  eachMonthOfInterval({ start, end }).forEach(month => {
      months.push(format(month, dateFormat, { locale }));
  });
  return months;
}

interface GetWeekDaysParams {
  locale: Locale,
  dateFormat?: string
}

export const getWeekDays = ({locale, dateFormat ='iiiii'}: GetWeekDaysParams): string[] => {
  const now: Date = new Date();
  const weekDays: string[] = [];
  const start: Date = startOfWeek(now, { locale });
  const end: Date = endOfWeek(now, { locale });
  eachDayOfInterval({ start, end }).forEach(day => {
      weekDays.push(format(day, dateFormat, { locale }));
  });
  //fix because first day is Sunday, not monday
  weekDays.unshift(weekDays.pop())
  return weekDays;
};
