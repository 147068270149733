import * as React from 'react';
import { useMessageCtx } from 'context/Message';
import { Grid, Layout, message, Tooltip, Typography } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { t } from '@lingui/macro';
import styled from 'styled-components';
import { LogoShort } from 'assets/logo';
import { AppMenu, ResponsiveMenu } from 'feature.menu';
import GlobalNav from 'feature.menu/components/GlobalNav';
import LanguageSelect from 'feature.menu/components/LanguageSelect';
// import ConnectionAlert from 'feature.menu/components/ConnectionAlert';
import { useAuthCtx } from 'context/Auth';
import { Link, useLocation } from 'react-router-dom';
import breakpoints from 'shared/breakpoints';

const StyledHeader = styled(Layout.Header)`
  display: flex;
  flex-direction: column;
  & > div:first-of-type {
    display: flex;
    align-items: center;
  }
  &.ant-layout-header {
    line-height: 24px;
    padding: 0;
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    boxshadow: 5px 8px 24px 5px rgba(208, 216, 243, 0.6);
    padding: 2px 30px 2px 20px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 3px;
    position: relative;
    z-index: 99;
    height: 108px;
    @media only screen and (${breakpoints.device.sm}) {
      height: 64px;
    }
    @media only screen and (${breakpoints.device.lg}) {
      height: 32px;
    }
  }
`;

const MobileNavWrapper = styled.div`
  display: inline-block;
  @media only screen and (${breakpoints.device.lg}) {
    display: none;
  }

  & > .ant-space-horizontal {
    justify-content: flex-end;
    @media only screen and (${breakpoints.device.lg}) {
      justify-content: flex-start;
    }
  }
`;

const DesktopNavWrapper = styled.div`
  display: none;
  @media only screen and (${breakpoints.device.lg}) {
    display: inline-block;
  }
`;

const ResponsiveContent = styled(Layout.Content)`
  & .ant-pro-card-body {
    padding-left: 6px;
    padding-right: 6px;

    @media only screen and (${breakpoints.device.md}) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
`;

//todo: fix same messages doesn't trigger message popup
function Message() {
  const { message: msg } = useMessageCtx();

  React.useEffect(() => {
    const messageFn = message[msg.type];
    if (messageFn) messageFn(msg.text);
  }, [msg.text, msg.type]);

  return null;
}

const useIsAuthPage = () => {
  const location = useLocation();
  const [isAuthPage, setIsAuthPage] = React.useState(false);

  React.useEffect(() => {
    setIsAuthPage(['/login'].includes(location.pathname));
  }, [location.pathname]);

  return { isAuthPage };
};

const TopbarUtility = () => {
  const { user, logout } = useAuthCtx();

  return (
    <>
      <Typography.Text>
        <UserOutlined /> <Typography.Text strong>{user}</Typography.Text>
      </Typography.Text>
      <div style={{ width: '15px' }} />
      <LanguageSelect />
      <div style={{ width: '15px' }} />
      <Typography.Link onClick={logout}>
        <Tooltip title={t`Logout`}>
          <LogoutOutlined />
        </Tooltip>
      </Typography.Link>
    </>
  );
};

const Page = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;

  const { isAuthPage } = useIsAuthPage();

  const screens = Grid.useBreakpoint();

  if (isAuthPage) {
    return (
      <Layout>
        {children}
        <Message />
      </Layout>
    );
  }

  return (
    <>
      {/* <ConnectionAlert /> */}
      <Layout style={{ maxHeight: '100vh', minHeight: '100vh' }}>
        <StyledHeader theme="light" className="site-layout-background">
          <div>
            <Link to="/">
              <LogoShort />
            </Link>
            <DesktopNavWrapper>
              <GlobalNav />
            </DesktopNavWrapper>
            <div style={{ marginLeft: 'auto' }} />
            {screens.sm ? (
              <TopbarUtility />
            ) : (
              <ResponsiveMenu>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Link to="/">
                    <LogoShort />
                  </Link>
                  <DesktopNavWrapper>
                    <GlobalNav />
                  </DesktopNavWrapper>
                  <div style={{ marginLeft: 'auto' }} />
                  <TopbarUtility />
                </div>
              </ResponsiveMenu>
            )}
          </div>

          <MobileNavWrapper>
            <div style={{ height: '5px' }} />
            <GlobalNav />
          </MobileNavWrapper>
        </StyledHeader>
        <Layout className="site-layout">
          {screens.sm ? <AppMenu /> : null}
          <ResponsiveContent
            className="site-layout-background"
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            {children}
          </ResponsiveContent>
        </Layout>
        <Message />
      </Layout>
    </>
  );
};

export default Page;
