import * as React from 'react';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { localStorageAPI } from 'utils/localStorageAPI';

export type LocaleEnum = 'en' | 'cs' | 'sk';

export const LANGUAGE_STORAGE_KEY = 'language_code';

const DEFAULT_LOCALE: LocaleEnum =
  localStorageAPI.getValue<LocaleEnum>(LANGUAGE_STORAGE_KEY) || 'cs';

export async function dynamicActivate(locale = DEFAULT_LOCALE) {
  try {
    // ! for some reason import('locales') doesn't seem to work, so using this instead
    const { messages } = await Promise.resolve(require(`../locales/${locale}/messages`));
    i18n.load(locale, messages);
    i18n.loadLocaleData({ [locale]: { plurals: messages } });
    i18n.activate(locale);
  } catch (e) {
    console.error(e);
  }
}

export const LinguiProvider = (props: React.PropsWithChildren<{}>) => {
  React.useEffect(() => {
    dynamicActivate();
  }, []);

  return <I18nProvider i18n={i18n}>{props.children}</I18nProvider>;
};
