import * as qs from 'query-string';

export const searchQueryAPI = {
  getValue: (key: string): string | null => {
    const searchParamsInURL = new URLSearchParams(window.location.search);
    if (!searchParamsInURL) {
      return null;
    }
    if (searchParamsInURL.has(key)) {
      return searchParamsInURL.get(key);
    }
    return null;
  },
  setValue: (key: string, value: string) => {
    const currentParams = qs.parse(window.location.search);

    if (!value) {
      delete currentParams[key];
    } else {
      currentParams[key] = value;
    }

    const stringified = qs.stringify(currentParams);

    const currentURL = window.location.pathname;

    window.history.replaceState({}, '', `${currentURL}?${stringified}`);
  },
};
