import { Message } from './../../types/index';
import { SET_MESSAGE } from './messageActions';

export const messageReducer = (
  state: { message: Message },
  action: { type: string; payload: Message }
) => {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        ...state,
        message: {
          type: action.payload.type,
          text: action.payload.text,
        },
      };
    default:
      return state;
  }
};
