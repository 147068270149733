import styled from 'styled-components';

export const DashboardWrapper = styled.div`
    padding: 25px 50px 50px 50px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  
  .dashboard-header {
    width: 100%;
    .ant-select {
      min-width: 150px;
    }
  }
`;

interface WidgetWrapperProps {
  width?: string,
  height?: string,
}

export const WidgetWrapper = styled.div<WidgetWrapperProps>`
  background: #fff;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #DFE0EB;
  width: ${props => props.width ? props.width : '49.5%'};
  height: ${props => props.height ? props.height : '360px'};
  margin: 0 0 20px 0;  
`;

export const ChartWrapper = styled.div`
  height: calc(100% - 40px);
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .echarts-for-react {
    height: 100% !important;
    width: 100% !important;
  }
`;

export const SettingsWrapper = styled.div`
  .ant-form-item {
    margin-bottom: 0;
  }
  
  .ant-select {
    min-width: 150px;
  }
`;
