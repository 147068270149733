const size = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

const device = {
  xs: `max-width: ${size.sm}`,
  sm: `min-width: ${size.sm}`,
  md: `min-width: ${size.md}`,
  lg: `min-width: ${size.lg}`,
};

const breakpoints = { size, device };
export default breakpoints;
