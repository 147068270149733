import { resources, STATUS } from 'api';
import { post } from 'app/axiosSetup';
import { t } from '@lingui/macro';
import { Row } from 'types';

export const changeData = async <TPayload>(
  ids: string[],
  payload: TPayload
): Promise<{ type: 'error' | 'success'; message: string; data?: Row[] }> => {
  const formattedPayload = {};

  ids.forEach((id) => {
    formattedPayload[id] = payload;
  });

  try {
    const response = await post<{ [key: number]: TPayload }, Row[]>(
      resources.queueData,
      formattedPayload
    );

    if (response.status === STATUS.BAD_REQ) {
      return {
        type: 'error',
        message: t`We are sorry but were not able to change state of ${response.data
          .map((datum) => datum.id)
          .toString()}. Please try again later.`,
      };
    } else if (response.status === STATUS.OK) {
      return {
        type: 'success',
        message: t`Record with ID ${response.data
          .map((datum) => datum.id)
          .toString()} was successfully changed to '${response.data[0].state}'`,
        data: response.data,
      };
    } else {
      return {
        type: 'success',
        message: t`We changed state of ${response.data
          .map((datum) => datum.id)
          .toString()} but there was problem with ${response.data}`,
        data: response.data,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      type: 'error',
      message: t`We are sorry but something went wrong. Please try again later.`,
    };
  }
};
