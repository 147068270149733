import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useLingui } from '@lingui/react';
import { Space, Spin, Typography, Select, Form, message } from 'antd';
import type { ActionType, ProFormInstance } from '@ant-design/pro-components';
import { EditableProTable } from '@ant-design/pro-components';
import { Trans, t } from '@lingui/macro';
import * as qs from 'query-string';
import { useOnMount } from 'shared/hooks/lifecycle';
import { resources } from 'api';
import { get, post, put, destroy } from 'app/axiosSetup';
import { useGlobalFiltersCtx } from 'context/GlobalFilters';
import { ColumnFilters } from './Filters';
import { getColumns } from './Schema';
import { StyleWrapper } from './style';
import {
  SelectedProvider,
  // useSelectedAPI,
} from './context';
import { useInitialDataCtx } from 'context/InitialData';
import { theme } from 'shared/theme';
import { CenterWrapper } from 'shared/generalStyles';
import {
  scrollProps,
  rowClassNameProps,
  processFilters,
  getInitialColFilters,
  updateUrl,
  SaveButton,
  CancelButton
} from './shared';
import { SyncDataConversion, SyncDataConversionRow } from 'types';
// import { useMessageCtx } from 'context/Message';
import { SortOrder } from 'antd/lib/table/interface';

type AgendaKeyPair = {
  id?: string;
  object_agenda: string;
  key_sw1: string;
  key_sw2: string;
}

type KeyPair = {
  id?: string;
  key_sw1: string;
  key_sw2: string;
}

type TableSettings = {
  modules: Array<{id: number, title: string}>
  scenario: number,
  key_sw1: string,
  key_sw2: string
}

const CustomTable = () => {
  const actionRef = React.useRef<ActionType>();
  const formRef = React.useRef<ProFormInstance>();
  const [editableKeys, setEditableRowKeys] = React.useState<React.Key[]>([]);
  const firstRequest = React.useRef(true);
  const columnFilters = React.useRef<ColumnFilters>(getInitialColFilters());
  const ordering = React.useRef<{ ordering?: string | undefined }>({});

  const { organization, project } = useGlobalFiltersCtx();
  // const { setMessage } = useMessageCtx();
  const { i18n } = useLingui();
  // const { setSelected } = useSelectedAPI();

  const [agendaList, setAgendaList] = React.useState<string[]>([]);
  const [keyPairList, setKeyPairList] = React.useState<KeyPair[]>([]);
  const [agendaKeysList, setAgendaKeysList] = React.useState<AgendaKeyPair[]>([]);
  const [agenda, setAgenda] = React.useState<string>('');
  const [keyPair, setKeyPair] = React.useState<KeyPair>(null);
  const [noData, setNoData] = React.useState<boolean>(false);

  // const [columns, setColumns] = React.useState<any>([]);
  const [dataSource, setDataSource] = React.useState<SyncDataConversionRow[]>([]);
  const [tableDataSettings, setTableDataSettings] = React.useState<TableSettings>(null);
  const [total, setTotal] = React.useState<number>(0);

  //useDefaultPage(actionRef);

  const handleAdd = (data: SyncDataConversionRow) => {
    post<SyncDataConversionRow, SyncDataConversionRow | { "success": boolean; "status_msg": string; }>
    (resources.transformations,
      {
        "scenario": tableDataSettings.scenario,
        "modules": tableDataSettings.modules.map(module => module.id),
        "object_agenda": agenda,
        "key_sw1": tableDataSettings.key_sw1,
        "value_sw1": data.value_sw1,
        "key_sw2": tableDataSettings.key_sw2,
        "value_sw2": data.value_sw2
      }
    )
      .then((response: { data: any, status: number }) => {
        if ('success' in response.data && response.data.success === false) {
          //todo: hotfix, delete this line and uncomment next line after problems with same messages will be resolved
          message.error(t({id:response.data.status_msg}))
          //setMessage('error', t({id:response.data.status_msg}));
          setEditableRowKeys([-1])
        } else {
          actionRef.current.reload();
        }
      })
  }

  const handleUpdate = async (data: SyncDataConversionRow) => {
    put(`${resources.transformations}${data.id}`, data)
    .then((response) => actionRef.current.reload())
    .finally(() => setEditableRowKeys([]))
  }

  const handleDelete = (data: SyncDataConversionRow) => {
    destroy(`${resources.transformations}${data.id}`, data)
    .then((response) => actionRef.current.reload())
    .finally(() => setEditableRowKeys([]))
  }

  const handleCancel = () => {
    setDataSource(
      dataSource.filter((existingRow, existingIndex) => !existingRow.new_row)
    )
    setEditableRowKeys([])
  }

  const fetchAgendaKeys = () => {
    const organizationFilter = organization ? { organization } : { organization };
    const projectFilter = project ? { project } : { project };
    const parameters = {
      ...projectFilter,
      ...organizationFilter
    };

    return get(resources.transformationsAgendaKeys, {
      params: parameters,
    })
    .then((response: AgendaKeyPair[]) => {
      if(response.length){
        response = response.map(item => ({id: uuid(), ...item}));
        const currentAgenda = response[0].object_agenda;

        setAgendaList(Array.from(new Set(response.map(item => item.object_agenda))));
        setKeyPairList(
          response
            .filter(item => item.object_agenda === currentAgenda)
            .map(item => ({id: item.id, key_sw1: item.key_sw1, key_sw2: item.key_sw2}))
        );
        setAgendaKeysList(response);

        setAgenda(currentAgenda);
        setKeyPair({id: response[0].id, key_sw1: response[0].key_sw1, key_sw2: response[0].key_sw2});
      } else {
        setNoData(true);
      }
    });
  }

  const generateColumns = () => {
    return getColumns(
      i18n.locale,
      {handleDelete})
      .map((col, i) => {
      if(tableDataSettings){
        if (i === 0){
          col.title = tableDataSettings.modules[0].title
          col['children'][0]['title'] = tableDataSettings.key_sw1
        }

        if (i === 1){
          col.title = tableDataSettings.modules[1].title
          col['children'][0]['title'] = tableDataSettings.key_sw2
        }
      }
      return col
    });
  }

  const handleRequest = async (params: {
      pageSize?: number;
      current?: number;
      keyword?: string;
    } = {}, sort: Record<string, SortOrder>, filter: Record<string, React.ReactText[] | null>) => {
    const originalParams = firstRequest.current ? qs.parse(window.location.search) : {};
    if (firstRequest.current) firstRequest.current = false;

    const organizationFilter = organization ? { organization } : { organization };
    const projectFilter = project ? { project } : { project };

    columnFilters.current = Object.assign({}, processFilters(filter));

    // empty table if no organization and project were selected

    const parameters = {
      ...originalParams,
      ...ordering.current,
      ...projectFilter,
      ...organizationFilter,
      ...columnFilters.current,
      page: Number(params.current || 1),
      ordering: '-id',
      key_sw1: keyPair?.key_sw1,
      key_sw2: keyPair?.key_sw2,
    };

    updateUrl(parameters)

    if(!organization || !project || !keyPair){
      return {data: [], success: true, total: 0}
    }

    const response = await get<SyncDataConversion>(resources.transformations, {
      params: parameters,
    });

    if(response.results.length > 0){
      const firstRow: SyncDataConversionRow = response.results[0];
      setTableDataSettings({
        modules: [
          {
            id: firstRow.modules[0]['id'],
            title: firstRow.modules[0]['module'].title,
          },
          {
            id: firstRow.modules[1]['id'],
            title: firstRow.modules[1]['module'].title,
          }
        ],
        scenario: firstRow.scenario,
        key_sw1: firstRow.key_sw1,
        key_sw2: firstRow.key_sw2
      })
    } else {
      setDataSource([])
      return { data: [], success: true, total: 0 }
    }

    setDataSource(response.results)
    setTotal(response.count)
    return { data: response.results, success: !!response, total: response.count };
  }

  const handleAgendaSwitch = useCallback((newAgenda) => {
    if (agenda && agendaKeysList.length) {
      actionRef.current.setPageInfo({current: 1});
      const agendaKeys = agendaKeysList.find(agendaKeys => agendaKeys.object_agenda === newAgenda);
      setKeyPair({
        id: agendaKeys.id,
        key_sw1: agendaKeys.key_sw1,
        key_sw2: agendaKeys.key_sw2,
      });

      setKeyPairList(
        agendaKeysList
          .filter(item => item.object_agenda === newAgenda)
          .map(item => ({id: item.id, key_sw1: item.key_sw1, key_sw2: item.key_sw2}))
      );
      setAgenda(newAgenda);
    }
  }, [agenda, agendaKeysList]);

  const handleKeyPairSwitch = (value: string) => {
    actionRef.current.setPageInfo({current: 1})
    setEditableRowKeys([]);
    handleCancel()
    setKeyPair(keyPairList.find(keyPair => keyPair.id === value))
  }

  useOnMount(() => fetchAgendaKeys())

  useEffect(() => {
    columnFilters.current = {};
    setNoData(false);
    setTableDataSettings(null);
    setAgenda('');
    setAgendaList([]);
    setKeyPairList([]);
    setAgendaKeysList([]);
    fetchAgendaKeys();
    setEditableRowKeys([]);
    // eslint-disable-next-line
  }, [project])

  useEffect(() => {
    columnFilters.current = {};
    setNoData(false);
    setTableDataSettings(null);
    setAgenda('');
    setAgendaList([]);
    setKeyPairList([]);
    setAgendaKeysList([]);
    fetchAgendaKeys();
    // eslint-disable-next-line
  }, [i18n.locale])

  useEffect(() => {
    if(actionRef?.current) {
      actionRef.current.reload()
    }
  }, [keyPair]);

  const optionsSettings = {
    setting: false,
    fullScreen: false,
    reload: (e: React.MouseEvent<HTMLSpanElement>, action?: ActionType) => {
      setEditableRowKeys([])
      handleCancel()
      fetchAgendaKeys().then(() => actionRef.current.reload())
    },
    density: false,
  }

  const headerTitleRenderer = (
    <Space>
      <Form.Item label={t`Agenda`}>
        <Select<string>
          value={agenda}
          onChange={handleAgendaSwitch}
        >
          {
            agendaList.map((agenda: string) =>
              (<Select.Option key={agenda} value={agenda}>{agenda}</Select.Option>)
            )
          }
        </Select>
      </Form.Item>
      {keyPair &&
      <Form.Item label={t`Key pair`}>
        <Select
          value={keyPair.id}
          onChange={handleKeyPairSwitch}
        >
          {
            keyPairList
              .map((keyPair: KeyPair) => (
                <Select.Option key={uuid()} value={keyPair.id}>
                  {keyPair.key_sw1} <b>/</b> {keyPair.key_sw2}
                </Select.Option>)
              )
          }
        </Select>
      </Form.Item>
      }
    </Space>
  );

  if(noData){
    return (<StyleWrapper>
      <CenterWrapper>
        No Data
      </CenterWrapper>
    </StyleWrapper>);
  }

  return (
    <StyleWrapper>
        <EditableProTable<SyncDataConversionRow>
          dataSource={dataSource}
          value={dataSource}
          columns={generateColumns()}
          actionRef={actionRef}
          formRef={formRef}
          recordCreatorProps={
            {
              position: 'top',
              newRecordType: 'dataSource',
              onClick: () => setTotal(total + 1),
              record: () => {
                return {
                  id: -1,
                  object_agenda: '',
                  key_sw1: '',
                  key_sw2: '',
                  value_sw1: '',
                  value_sw2: '',
                  new_row: true,
                  scenario: null,
                  modules: []
                }
              },
              creatorButtonText: t`add row`
            }
          }
          request={handleRequest}
          controlled={true}
          rowClassName={rowClassNameProps}
          headerTitle={headerTitleRenderer}
          options={optionsSettings}
          editable={{
            type: 'single',
            onlyAddOneLineAlertMessage: t`You can add only one row`,
            onlyOneLineEditorAlertMessage: t`You can edit only one row`,
            cancelText: <CancelButton />,
            saveText: <SaveButton />,
            editableKeys,
            onSave: async (key, record, originRecord, newLineConfig) => {
              if(record.new_row){
                setEditableRowKeys([-1])
                handleAdd(record)
              } else {
                handleUpdate(record)
              }
            },
            onCancel: async (key, record, originRecord, newLineConfig) => {
              handleCancel()
            },
            onValuesChange: (record, recordList) => {
              setDataSource(recordList);
            },
            onChange: (keys, rows) => {
              if(editableKeys.includes(-1))
                setEditableRowKeys([-1, ...keys])
              else
                setEditableRowKeys(keys)
            },
            actionRender: (record, config, dom) => {
              return [
                dom.save,
                dom.cancel
              ]
            }
          }}
          rowKey="id"
          search={{filterType: 'light'}}
          size="small"
          // rowSelection={{
          //   onChange(selectedRowKeys) {
          //     setSelected(selectedRowKeys);
          //   },
          //   alwaysShowAlert: false,
          // }}
          tableAlertRender={false}
          pagination={{
            showSizeChanger: false,
            defaultPageSize: 101,
            total: total,
            showTotal: (total, range) => (
              <div>{t`showing ${range[0]}-${range[1]} of ${total} total items`}</div>
            ),
          }}
          dateFormatter="string"
          scroll={scrollProps}
        />
    </StyleWrapper>
  );
};

const Table = () => {
  const { isLoading, isError } = useInitialDataCtx();

  if (isError) {
    return (
      <CenterWrapper>
        <Typography.Text style={{ color: theme.colors.error.main }}>
          <Trans>An unexpected error has occurred.</Trans>
        </Typography.Text>
      </CenterWrapper>
    );
  }

  if (isLoading) {
    return (
      <CenterWrapper>
        <Spin size="large" />
      </CenterWrapper>
    );
  }
  return (
    <SelectedProvider>
      <CustomTable />
    </SelectedProvider>
  );
};

export default Table;
