import styled from "styled-components";

interface CenterWrapperProps {
  height?: any
}

export const CenterWrapper = styled.div<CenterWrapperProps>`
  width: 100%;
  height: ${(props) => props.height ? props.height : '100%'};
  display: flex;
  align-items: center;
  justify-content: center;
`;
