import styled from 'styled-components';
import { theme } from 'shared/theme';
import { scrollbarStyles } from 'shared/scrollbarStyles';
import { Space } from 'antd';
import breakpoints from 'shared/breakpoints';

const StyleWrapper = styled.div`
  height: 100%;
  .ant-pro-table table {
    font-size: 12px;
    .ant-checkbox-inner {
      border: 1px solid ${theme.colors.primary.main};
    }
    .ant-table-row-expand-icon-cell {
      color: ${theme.colors.primary.main};
      max-width: 40px;
    }
    .ant-table-expanded-row {
      padding: 0px 0px 8px;
    }
  }

  // child table styles start
  .ant-pro-table.child-table {
    margin-top: 5px;

    .ant-table-body {
      overflow: auto auto !important;
    }

    .ant-pro-card-body{
      padding: 0;
        table {
          width: 100% !important;
        }
    }

    .ant-alert {
      padding: 10px 10px 10px 16px;
    }

    .ant-table-cell {
      padding: 2px 8px;
    }
  }

  // child table styles end

  .ant-divider-horizontal {
    margin: 20px 0 12px 0;
  }

  .ant-table-body {
    ${scrollbarStyles}
  }
  .ant-pagination {
    margin-bottom: 0px;
  }
  .ant-pro-card-body {
    padding-bottom: 8px;
  }
  .ant-table-row:hover {
    td {
      cursor: pointer;
    }
  }
  .ant-pro-table-list-toolbar-container {
    align-items: initial;
    @media only screen and (${breakpoints.device.sm}) {
      align-items: flex-start;
    }

    .ant-pro-table-list-toolbar-right {
      flex-direction: row;
      align-items: center;
    }
    .ant-pro-table-list-toolbar-left {
      margin-bottom: 8px;
      @media only screen and (${breakpoints.device.md}) {
        margin-bottom: 0px;
      }

      .ant-pro-table-list-toolbar-title > .ant-space {
        & > .ant-space-item:first-of-type {
          width: 100%;
          @media only screen and (${breakpoints.device.md}) {
            width: auto;
          }

          & > .ant-space {
            width: 100%;
            @media only screen and (${breakpoints.device.md}) {
              width: auto;
            }

            & > .ant-space-item:first-of-type {
              width: 100%;
              @media only screen and (${breakpoints.device.md}) {
                width: auto;
              }

              & > .ant-select {
                width: 100%;
                @media only screen and (${breakpoints.device.md}) {
                  width: auto;
                }
              }
            }
          }
        }
        & > .ant-space-item:nth-of-type(2) {
          width: 100%;
          @media only screen and (${breakpoints.device.md}) {
            width: auto;
          }

          & > .ant-space {
            width: 100%;
            @media only screen and (${breakpoints.device.md}) {
              width: auto;
            }

            & > .ant-space-item:nth-of-type(2) {
              width: 100%;
              @media only screen and (${breakpoints.device.md}) {
                width: auto;
              }

              & > .ant-input-search {
                width: 100%;
                @media only screen and (${breakpoints.device.md}) {
                  width: auto;
                }
              }
            }
          }
        }
      }
    }
  }

  ${'' /* .new-data {

  } */}

  ${'' /* .not-accepted {
    background: ${theme.colors.error.light};
    td {
      background: ${theme.colors.error.light} !important;
    }
    &:hover td {
      background: #eddddf !important;
    }
  } */}

  ${'' /* .not-processed {
    background: ${theme.colors.error.light};
    td {
      background: ${theme.colors.error.light} !important;
    }
    &:hover td {
      background: #eddddf !important;
    }
  } */}

  .failed {
    background: ${theme.colors.error.light};
    td {
      background: ${theme.colors.error.light} !important;
    }
    &:hover td {
      background: #eddddf !important;
    }
  }

  .processed {
    background: ${theme.colors.success.light};
    td {
      background: ${theme.colors.success.light} !important;
    }
    &:hover td {
      background: #d1e0d5 !important;
    }
  }
  .redundant{
    background: ${theme.colors.warning.light};
    td {
      background: ${theme.colors.warning.light} !important;
    }
    &:hover td {
      background: #fffaf0 !important;
    }
  }
`;

const ResponsiveSpace = styled(Space)`
  flex-wrap: wrap;
`;

export { StyleWrapper, ResponsiveSpace };
