import * as React from 'react';
import { useLingui } from '@lingui/react';
import { localStorageAPI } from 'utils/localStorageAPI';
import { dynamicActivate, LANGUAGE_STORAGE_KEY, LocaleEnum } from 'app/lingui';
import { Select } from 'antd';
import styled from 'styled-components';
import TinyFlag from 'tiny-flag-react';
import { t } from '@lingui/macro';

const StyledSelect = styled(Select)`
  padding-bottom: 2px;
  .ant-select-selector {
    border: none !important;
    height: 24px !important;
    & input {
      height: 22px !important;
    }
    & .ant-select-selection-item {
      line-height: 24px;
    }
  }
`;

const LanguageSelect = () => {
  const { i18n } = useLingui();

  const [lang, setLang] = React.useState(i18n.locale);

  const handleChange = (val: LocaleEnum) => {
    setLang(val);
    localStorageAPI.setValue(LANGUAGE_STORAGE_KEY, val);
    dynamicActivate(val);
  };

  return (
    <StyledSelect id="language-select" onChange={handleChange} value={lang}>
      <Select.Option value="en">
        <TinyFlag
          country="US"
          fallbackImageURL="https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/US.svg"
          alt={t`United States Flag`}
        />
      </Select.Option>
      <Select.Option value="cs">
        <TinyFlag
          country="CZ"
          fallbackImageURL="https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/CZ.svg"
          alt={t`Czech Flag`}
        />
      </Select.Option>
      <Select.Option value="sk">
        <TinyFlag
          country="SK"
          fallbackImageURL="https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/SK.svg"
          alt={t`Slovakia Flag`}
        />
      </Select.Option>
    </StyledSelect>
  );
};

export default LanguageSelect;
