import { t, Trans } from '@lingui/macro';
import {
  // Table as AntTable,
  Space,
} from 'antd';
import { SyncDataConversionRow } from 'types';
import { getTextColumnProps } from 'shared/table/filters';
import { EditButton, DeleteButton } from './shared';

const getColumns = (locale: string, {handleDelete}): any => [
  //AntTable.SELECTION_COLUMN,
  {
    title: 'Module 1',
    hideInSearch: true,
    onFilter: () => true,
    children: [
      {
        dataIndex: 'value_sw1',
        title: 'key_sw1',
        valueType: 'text',
        hideInSearch: false,
        width: '40%',
        editable: true,
        formItemProps: {
          rules: [
            {
              required: true,
              message: t`This field is required`,
            },
          ],
        },
        ...getTextColumnProps('value_sw1'),
      }
    ]
  },
  {
    title: 'Module 2',
    hideInSearch: true,
    onFilter: () => true,
    children: [
      {
        dataIndex: 'value_sw2',
        title: 'key_sw2',
        valueType: 'text',
        hideInSearch: false,
        width: '40%',
        editable: true,
        formItemProps: {
          rules: [
            {
              required: true,
              message: t`This field is required`,
            },
          ],
        },
        ...getTextColumnProps('value_sw2'),
      }
    ]
  },
  {
    title: <span style={{textAlign: 'center', display: 'block'}}><Trans>Action</Trans></span>,
    hideInSearch: true,
    valueType: 'option',
    width: '12%',
    render: (text: string, record: SyncDataConversionRow, index: number, action) => {
      return (
        <Space size={8}>
          <EditButton key='editable' onClick={() => action?.startEditable?.(record.id)}/>
          <DeleteButton key='delete' onClick={() => handleDelete(record)}/>
        </Space>
      )
    }
  }
];

export { getColumns };
