import React from 'react';
import Table from './components/Table';
import { Trans } from '@lingui/macro';
import { PageWrapper, PageHeaderWrapper } from './styles';
import { Typography, Divider } from 'antd';
import { useGlobalFiltersCtx } from 'context/GlobalFilters';
import { CenterWrapper } from 'shared/generalStyles';
const { Title } = Typography;

const Transformations = () => {
  const {project, organization} = useGlobalFiltersCtx();

  if(!project || !organization)
    return (
      <PageWrapper>
        <PageHeaderWrapper>
          <Title><Trans>Transformations</Trans></Title>
          <Divider />
        </PageHeaderWrapper>
        <CenterWrapper height={'calc(100vh - 110px)'}>
          <Trans>The organisation or project has not been selected</Trans>
        </CenterWrapper>
      </PageWrapper>
    )

  return <>
    <PageWrapper>
      <PageHeaderWrapper>
        <Title><Trans>Transformations</Trans></Title>
        <Divider />
      </PageHeaderWrapper>
      <Table />
    </PageWrapper>
  </>;
};

export default Transformations;
