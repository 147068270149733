import * as React from 'react';

import { t, Trans } from '@lingui/macro';

import { useAuthCtx } from 'context/Auth';

import { SyncAgentLogoMain, SyncAgentLogoWhite } from 'assets/logo';

import { Input, InputRef } from 'antd';
import {
  Head,
  Separator,
  Wrapper,
  StyledLoginForm,
  SubmitBtn,
  FormItem,
} from 'feature.auth/styles';

const LoginForm = () => {
  const { login } = useAuthCtx();

  const emailRef = React.useRef<InputRef>();
  const passwordRef = React.useRef<InputRef>();

  const onFinish = async () => {
    await login(emailRef.current.input.value, passwordRef.current.input.value);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Wrapper>
      <Head>
        <img className="logo-md" alt="logo" src={SyncAgentLogoMain} />
        <img className="logo-xs" alt="logo" src={SyncAgentLogoWhite} />
      </Head>

      <Separator />
      <StyledLoginForm onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
        <FormItem
          label={t`Email address`}
          name="email_address"
          // rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input ref={emailRef} width="100%" />
        </FormItem>

        <FormItem
          label={t`Password`}
          name="password"
          // rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password ref={passwordRef} width="100%" />
        </FormItem>

        <FormItem>
          <SubmitBtn type="primary" htmlType="submit" size="large">
            <Trans>Login</Trans>
          </SubmitBtn>
        </FormItem>
      </StyledLoginForm>
    </Wrapper>
  );
};

export default LoginForm;
