import { Layout } from 'antd';
import React from 'react';

import MenuContent from './MenuContent';
import MenuIcon from './MenuIcon';

const AppMenu: React.FC = () => {
  const [collapsed, setCollapsed] = React.useState(true);

  return (
    <Layout.Sider
      style={{ position: 'relative', background: '#fff' }}
      trigger={null}
      collapsible
      collapsed={collapsed}
      collapsedWidth={40}
      width={180}
      breakpoint="md"
    >
      <MenuIcon onClick={() => setCollapsed(!collapsed)} collapsed={collapsed} absolutePosition />
      <MenuContent menuStyles={{ paddingTop: '40px' }} />
    </Layout.Sider>
  );
};

export default AppMenu;
