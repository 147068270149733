import * as React from 'react';
import { SET_AGENDA_LIST, SET_STATS_TYPE, SET_AGENDA } from './actions';
import { dashboardReducer } from './reducer';
import { TDashboardCtx, TDashboardAPICtx, AgendaListItem } from './types';

const initialState: TDashboardCtx = {
  agendaList: [],
  statType: 'all',
  agenda: ''
};

const DashboardContext = React.createContext<TDashboardCtx>(initialState as TDashboardCtx);
const DashboardAPIContext = React.createContext<TDashboardAPICtx>({} as TDashboardAPICtx);

export const DashboardProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(dashboardReducer, initialState as TDashboardCtx);

  const fns = React.useMemo(() => ({
    setAgendaList: (agendaList: AgendaListItem[]) => {
      dispatch({
        type: SET_AGENDA_LIST,
        payload: agendaList,
      });
    },
    setStatType: (statType: string) => {
      dispatch({
        type: SET_STATS_TYPE,
        payload: statType,
      });
    },
    setAgenda: (agenda: string) => {
      dispatch({
        type: SET_AGENDA,
        payload: agenda,
      });
    }
  }), [])

  return (
    <DashboardContext.Provider
      value={{
        agendaList: state.agendaList,
        statType: state.statType,
        agenda: state.agenda
      }}
    >
      <DashboardAPIContext.Provider
        value={{
          setAgendaList: fns.setAgendaList,
          setStatType: fns.setStatType,
          setAgenda: fns.setAgenda
        }}
      >
        {children}
      </DashboardAPIContext.Provider>
    </DashboardContext.Provider>
  );
};

export const useDashboardCtx = () => {
  return React.useContext(DashboardContext);
};

export const useDashboardAPICtx = () => {
  return React.useContext(DashboardAPIContext);
};
