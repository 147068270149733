import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, Divider, Dropdown, Menu, Tabs, Tooltip } from 'antd';
import { useMessageCtx } from 'context/Message';
import { changeData } from 'feature.home/data/queue-data';
import type { QueueRecordState } from 'types';
import {
  StyledIconWrapper,
  StyledProcessedIcon,
  StyledUnprocessedIcon,
  StyledProcessingIcon,
  StyledWarningIcon,
  StyledButton,
} from 'feature.home/styles/index';
import { getStateIdx, getStateType } from 'utils/mapping';
import { DeleteOutlined, RedoOutlined } from '@ant-design/icons';
import styled from 'styled-components';

type Payload = { state: QueueRecordState };

const UnprocessedOption = ({ onClick }: { onClick: (arg: Payload) => void }) => {
  const arg = { state: 'unprocessed' as QueueRecordState };

  return (
    <span
      onClick={(e) => {
        e.stopPropagation();
        onClick(arg);
      }}
    >
      <StyledUnprocessedIcon />
      &nbsp;<Trans>Mark as 'unprocessed'</Trans>
    </span>
  );
};

const ProcessingOption = ({ onClick }: { onClick: (arg: Payload) => void }) => {
  const arg = { state: 'unprocessed' as QueueRecordState }; // TODO: tmp send only 'unprocessed', switch to 'processing' later

  return (
    <span
      onClick={(e) => {
        e.stopPropagation();
        onClick(arg);
      }}
    >
      <RedoOutlined />
      &nbsp;<Trans>Process again</Trans>
    </span>
  );
};

const UnprocessedIcon = () => {
  return (
    <Tooltip title={t`Failed`}>
      <StyledUnprocessedIcon />
    </Tooltip>
  );
};

const ProcessedIcon = () => {
  return (
    <Tooltip title={t`Processed`}>
      <StyledProcessedIcon />
    </Tooltip>
  );
};

const ProcessingIcon = () => {
  return (
    <Tooltip title={t`Waiting for processing`}>
      <StyledProcessingIcon />
    </Tooltip>
  );
};

const RedundancyIcon = () => {
  return (
    <Tooltip title={t`Redundant`}>
      <StyledWarningIcon />
    </Tooltip>
  );
};

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  & .ant-tabs-nav-operations-hidden {
    display: none !important;
  }
  & .ant-tabs-tab-btn {
    display: flex;
    justify-content: center;
  }
  & .ant-tabs-tab {
    height: 32px;
    margin: 0 8px 0 8px;
    max-height: 30px;
    padding-bottom: 0px;
    width: 26px;
    display: flex;
    justify-content: center;
    & .anticon {
      margin-right: 6px;
      margin-left: 6px;
    }
  }
`;

export function StateTabs(props: {
  onChange: (value: QueueRecordState | '') => void;
  value: QueueRecordState | '';
}) {
  const handleChange = (newValue: string) => {
    props.onChange(getStateType(Number(newValue)));
  }

  const handleClick = (clickedValue: string) => {
    // reset state filter
    if (getStateType(Number(clickedValue)) === props.value) {
      props.onChange("");
    }
  }

  return (
    <div className="ant-select">
      <TabsWrapper className="ant-select-selector">
        <Tabs
          activeKey={getStateIdx(props.value).toString()}
          onChange={handleChange}
          onTabClick={handleClick}
        >
          <Tabs.TabPane tab={<UnprocessedIcon />} key={'1'} />
          <Tabs.TabPane tab={<ProcessedIcon />} key={'2'} />
          <Tabs.TabPane tab={<ProcessingIcon />} key={'3'} />
          <Tabs.TabPane tab={<RedundancyIcon />} key={'4'} />
        </Tabs>
        {props.value ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Divider type="vertical" />

            <Tooltip title={t`Remove filter`}>
              <Button
                type="text"
                onClick={() => handleChange('0')}
                style={{ padding: '4px 8px 8px 8px', maxHeight: '30px' }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </div>
        ) : null}
      </TabsWrapper>
    </div>
  );
}

function StateIndicator(props: { id: string; type: QueueRecordState, layout?: string }) {
  const { setMessage } = useMessageCtx();

  const onChangeData = async (payload: Payload) => {
    const result = await changeData<Payload>([props.id], payload);

    setMessage(result.type, result.message);
  };

  function renderIndicator(type: QueueRecordState) {
    switch (type) {
      case 'processed':
        return (
          <StyledIconWrapper shape="circle" size="small" aria-label={t`Processed`}>
            <ProcessedIcon />
          </StyledIconWrapper>
        );
      case 'processing':
        return (
          <StyledIconWrapper shape="circle" size="small" aria-label={t`Processing`}>
            <ProcessingIcon />
          </StyledIconWrapper>
        );
      case 'redundant':
        return (
          <StyledIconWrapper shape="circle" size="small" aria-label={t`Redundant`}>
            <RedundancyIcon />
          </StyledIconWrapper>
        );

      default:
        return (
          <StyledIconWrapper shape="circle" size="small" aria-label={t`Unprocessed`}>
            <UnprocessedIcon />
          </StyledIconWrapper>
        );
    }
  }

  function getMenuItems(type: QueueRecordState) {
    const handleOptionClick = (arg: Payload) => {
      onChangeData(arg);
    };

    switch (type) {
      case 'processed':
        return [
          {
            key: 'processed',
            label: '',
            icon: <ProcessingOption key="1" onClick={handleOptionClick} />,
          },
        ];
      case 'processing':
        return [
          {
            key: 'processing',
            label: '',
            icon: <UnprocessedOption key="1" onClick={handleOptionClick} />,
          },
        ];
      default:
        return [
          {
            key: 'unprocessed',
            label: '',
            icon: <ProcessingOption key="1" onClick={handleOptionClick} />,
          },
        ];
    }
  }

  function getProcessButton(type: QueueRecordState) {
    const handleOptionClick = (arg: Payload) => {
      onChangeData(arg);
    };

    switch (type) {
      case 'processed':
        return (
          <StyledButton type="primary">
            <ProcessingOption onClick={handleOptionClick} />
          </StyledButton>
        );
      case 'processing':
        return (
          <StyledButton type="primary">
            <UnprocessedOption onClick={handleOptionClick} />
          </StyledButton>
        );
      default:
        return (
          <StyledButton type="primary">
            <ProcessingOption onClick={handleOptionClick} />
          </StyledButton>
        );
    }
  }

  if(!props.layout || props.layout === 'compact') {
    const menu = <Menu items={getMenuItems(props.type)} />;
    return <Dropdown overlay={menu}>{renderIndicator(props.type)}</Dropdown>;
  } else if (props.layout === 'spread') {
    return (
      <>
        {renderIndicator(props.type)}
        {getProcessButton(props.type)}
      </>
    )
  }
}

export default StateIndicator;
