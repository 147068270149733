import { Drawer } from 'antd';
import React from 'react';

import MenuContent from './MenuContent';
import MenuIcon from './MenuIcon';

const PLACEMENT = 'top';

const ResponsiveMenu: React.FC = (props) => {
  const [collapsed, setCollapsed] = React.useState(false);

  const onToggle = () => {
    setCollapsed((prevState) => !prevState);
  };

  const onClose = () => {
    setCollapsed(false);
  };

  return (
    <>
      <MenuIcon onClick={onToggle} collapsed={collapsed} />
      <Drawer
        title={props.children}
        placement={PLACEMENT}
        closable={false}
        onClose={onClose}
        visible={collapsed}
        key={PLACEMENT}
        contentWrapperStyle={{ height: '200px' }}
      >
        <MenuContent />
      </Drawer>
    </>
  );
};

export default ResponsiveMenu;
