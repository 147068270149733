import { useEffect, useState } from 'react';
import { resources } from 'api';
import { put } from 'app/axiosSetup';
// todo: react-json-view isn't maintained anymore. Switch to another package(ex. @textea/json-viewer, but there is a bug)
import ReactJson from 'react-json-view';
import styled from 'styled-components';
import { StyledButton } from '../styles';
import { useMessageCtx } from 'context/Message';
import { Trans, t } from '@lingui/macro';

const StyledDataDescription = styled.div`
  
  //hide brackets
  .icon-container + span + span {
    color: red !important;
    text-transform: uppercase !important;
    opacity: 0;
  }
  
  .brace-row {
    opacity: 0;
  }
  
  .react-json-view {
    max-height: 550px;
    min-height: 550px;
    height: 100%;
    overflow-y: auto;
    padding: 10px 0 0 0;
  }
`;

const StyledDataDescriptionFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px;
  border-top: 1px solid #eee;
`;

interface DataDescriptionProps {
  json: object,
  id: number | string,
  onChange?: () => void
}

function DataDescription(props: DataDescriptionProps){
  const {setMessage} = useMessageCtx();
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [data, setData] = useState<object>({})
  const displayDataTypes: boolean = false

  useEffect(() => {
    setData(props.json)
  }, [props.json])

  function onEditHandler(e){
    setData(e.updated_src)
    setIsChanged(true)
  }

  function saveHandler(){
    put(
      `${resources.queueData}${props.id}/`,
      {
        data: data,
      }
    )
    .then(() => setMessage('success', t`The data has been updated`))
    .catch(() => setMessage('error', t`An unexpected error has occurred`))
    .finally(() => {
      props.onChange();
      setIsChanged(false)
    })
  }

  return (
    <StyledDataDescription>
      <ReactJson src={data} onEdit={onEditHandler} displayDataTypes={displayDataTypes}/>
      <StyledDataDescriptionFooter>
        <StyledButton type={'primary'} disabled={!isChanged} onClick={saveHandler}><Trans>Save</Trans></StyledButton>
      </StyledDataDescriptionFooter>
    </StyledDataDescription>
  )
}

export default DataDescription
