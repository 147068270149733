import * as React from 'react';
import Particles from 'react-tsparticles';
import { theme } from 'shared/theme';

export const ParticlesBG = () => {
  return (
    <Particles
      id="tsparticles"
      width="100vw"
      height="100vh"
      style={{ display: 'block' }}
      options={{
        background: {
          color: {
            value: theme.colors.primary.main,
          },
        },
        fpsLimit: 60,
        interactivity: {
          detectsOn: 'canvas',
          events: {
            onClick: {
              enable: false,
              mode: 'push',
            },
            onHover: {
              enable: false,
              mode: 'repulse',
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 400,
              duration: 2,
              opacity: 0.25,
              size: 40,
            },
            push: {
              quantity: 60,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: theme.colors.primary.text,
          },
          links: {
            color: theme.colors.primary.text,
            distance: 150,
            enable: true,
            opacity: 0.15,
            width: 1,
          },
          collisions: {
            enable: false,
          },
          move: {
            direction: 'none',
            enable: true,
            outMode: 'bounce',
            random: false,
            speed: 2,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              value_area: 800,
            },
            value: 80,
          },
          opacity: {
            value: 0.25,
          },
          shape: {
            type: 'circle',
          },
          size: {
            random: true,
            value: 5,
          },
        },
        detectRetina: true,
      }}
    />
  );
};
