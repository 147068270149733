import React from 'react';
import { Input, InputRef, Tooltip } from 'antd';
import { Button, DatePicker, Space } from 'antd';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import type { ProColumns } from '@ant-design/pro-components';
import moment from 'moment';
import { t, Trans } from '@lingui/macro';

type ColumnFilters = { [key: string]: string | string[] };

const getDate = (initialDate: string) => {
  if (initialDate) return moment(initialDate);
  return moment();
};

const getDateColumnProps = (dataIndex: string): ProColumns<any> => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Space style={{ alignItems: 'stretch' }}>
        <DatePicker.RangePicker
          format={'DD-MM-YY'}
          onChange={(e) => {
            if (!e) return;
            setSelectedKeys([e[0].toISOString(), e[1].toISOString()]);
          }}
          value={[getDate(selectedKeys[0] as any), getDate(selectedKeys[1] as any)]}
          allowClear={false}
        />
        <Button
          onClick={() => {
            setSelectedKeys([moment().toISOString(), moment().toISOString()]);
            clearFilters();
            confirm({ closeDropdown: true });
          }}
          size="small"
          style={{ width: 90, height: '100%' }}
        >
          <Trans>Clear</Trans>
        </Button>
        <Button
          type="primary"
          onClick={() => {
            confirm({ closeDropdown: true });
          }}
          size="small"
          style={{ width: 90, height: '100%' }}
        >
          <Trans>OK</Trans>
        </Button>
      </Space>
    </div>
  ),
});

const getTextColumnProps = (dataIndex: string): ProColumns<any> => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Space style={{ alignItems: 'stretch' }}>
        <Input
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => {
            confirm({ closeDropdown: true });
          }}
        />
        <Button
          onClick={() => {
            setSelectedKeys([]);
            clearFilters();
            confirm({ closeDropdown: true });
          }}
          size="small"
          style={{ width: 90, height: '100%' }}
        >
          <Trans>Clear</Trans>
        </Button>
        <Button
          type="primary"
          onClick={() => {
            confirm({ closeDropdown: true });
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, height: '100%' }}
        >
          <Trans>OK</Trans>
        </Button>
      </Space>
    </div>
  ),
});

const ClearFilters = (props: {
  columnFilters: React.MutableRefObject<ColumnFilters>;
  ordering: React.MutableRefObject<{ ordering?: string | undefined }>;
  search: React.MutableRefObject<InputRef>;
  onClearFilters: () => void;
}) => {
  const { columnFilters, onClearFilters, ordering, search } = props;

  const onClear = () => {
    columnFilters.current = {};
    ordering.current = {};
    search.current.input.value = '';
    onClearFilters();
  };

  const dropPageFilter = { ...columnFilters.current };
  delete dropPageFilter['page'];

  const noActiveFilters =
    !Object.keys(dropPageFilter).length &&
    !ordering.current.ordering &&
    !search.current?.input?.value;

  if (noActiveFilters) return null;

  return (
    <Tooltip title={t`Clear all filters`}>
      <div className="ant-pro-table-list-toolbar-setting-item" onClick={onClear}>
        <FilterOutlined />
      </div>
    </Tooltip>
  );
};

export { getDateColumnProps, getTextColumnProps, ClearFilters };
export type { ColumnFilters };
