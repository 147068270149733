import * as React from 'react';

import { ParticlesBG } from 'feature.auth/components/Particles';
import LoginForm from 'feature.auth/components/LoginForm';
import { useOnMount } from 'shared/hooks/lifecycle';

function Login() {
  useOnMount(() => (document.title = `SyncAgent`));

  return (
    <React.Fragment>
      <LoginForm />

      <ParticlesBG />
    </React.Fragment>
  );
}

export default Login;
