import * as React from 'react';
import type { ActionType } from '@ant-design/pro-components';
import { ProColumns, ProTable } from '@ant-design/pro-components';
import * as qs from 'query-string';

import type { QueueData, Row } from 'types';
import { resources } from 'api';
import { get } from 'app/axiosSetup';
import RecordDescription from '../RecordDescription';
import { useGlobalFiltersCtx } from 'context/GlobalFilters';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { ColumnFilters } from './Filters';
import { StyleWrapper } from './style';
import { useSelectedAPI } from './context';
import { scrollProps, rowClassNameProps, getInitialColFilters} from './shared';

interface ChildTableProps {
  record: Row,
  columns: ProColumns<Row>[]
}

const CustomTable = (props: ChildTableProps) => {
  const [dataSource] = React.useState<Row[]>(null);

  const actionRef = React.useRef<ActionType>();
  const { organization, project } = useGlobalFiltersCtx();
  const firstRequest = React.useRef(true);
  const alreadyFetchedData = React.useRef<Row[]>(null);

  const { setSelected } = useSelectedAPI();

  const columnFilters = React.useRef<ColumnFilters>(getInitialColFilters());
  const ordering = React.useRef<{ ordering?: string | undefined }>({});

  const expandableSettings = {
    expandedRowRender: (record) => <RecordDescription record={record} />,
    expandRowByClick: true,
    expandIcon: ({ expanded, onExpand, record }) =>
      expanded ? (
        <CaretUpOutlined onClick={(e) => onExpand(record, e)} />
      ) : (
        <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
      ),
    columnWidth: 20,
  };

  const handleRequest = async (params: {
      pageSize?: number;
      current?: number;
      keyword?: string;
    } = {}) => {
    const originalParams = firstRequest.current ? qs.parse(window.location.search) : {};
    if (firstRequest.current) firstRequest.current = false;

    const organizationFilter = organization ? { organization } : {};
    const projectFilter = project ? { project } : {};

    const searchByParent = {
      event_id: props.record.event_id,
      time_created__lt: props.record.time_created,
      id__gt: props.record.id
    }

    const parameters = {
      ...originalParams,
      page: Number(params.current || 1),
      ...ordering.current,
      ...projectFilter,
      ...organizationFilter,
      ...columnFilters.current,
      ...searchByParent,
      descendant: true
    };

    // empty table if no organization and project were selected
    if(!parameters.organization || !parameters.project)
      return {data: [], success: false, total: 0}

    const response = await get<QueueData>(resources.queueData, {
      params: parameters,
    });

    alreadyFetchedData.current = response.results;

    let data = [];
    data = response.results;

    return { data: data, success: !!response, total: response.count };
  }

  const addProps = dataSource ? { dataSource } : {};

  return (
    <StyleWrapper>
      <ProTable<Row>
        {...addProps}
        className={'child-table'}
        columns={props.columns}
        actionRef={actionRef}
        request={handleRequest}
        editable={{type: 'multiple'}}
        headerTitle={false}
        showHeader={false}
        rowClassName={rowClassNameProps}
        rowKey="id"
        search={false}
        options={false}
        size="small"
        rowSelection={{
          onChange(selectedRowKeys) {
            setSelected(selectedRowKeys);
          },
          alwaysShowAlert: false,
        }}
        tableAlertRender={false}
        pagination={false}
        expandable={expandableSettings}
        dateFormatter="string"
        scroll={scrollProps}
      />
    </StyleWrapper>
  );
};

const ChildTable = (props: ChildTableProps) => {
  return (
    <CustomTable columns={props.columns} record={props.record}/>
  );
};

export default ChildTable;
