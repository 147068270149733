import * as React from 'react';
// import { useConnectionsAndSites } from 'feature.home/hooks/useConnectionsAndSites';
import { Select, Space, Spin, Typography } from 'antd';
import { Trans, t } from '@lingui/macro';
import { changeTitle } from 'utils/document';
import styled from 'styled-components';
import { useGlobalFiltersAPICtx, useGlobalFiltersCtx } from 'context/GlobalFilters';
import {
  useAllOption,
  useInitialDataCtx,
  useOrganizations, useOrganizationsList,
  useProjects,
} from 'context/InitialData';
import { theme } from 'shared/theme';
import { useOnMount } from 'shared/hooks/lifecycle';
import {useEffect, useState} from "react";

const { Text } = Typography;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 24px !important;
    & input {
      height: 22px !important;
    }
    & .ant-select-selection-item {
      line-height: 24px;
    }
  }

  &.ant-select-status-error.ant-select-disabled {
    .ant-select-selector {
      border-color: ${theme.colors.error.main} !important;
      background: #fff;
    }

    .ant-select-selection-placeholder {
      line-height: 22px;
      color: ${theme.colors.error.main};
    }

    .ant-select-arrow svg{
      fill: ${theme.colors.error.main};
    }
  }
`;

const StyledSpace = styled(Space)`
  flex-wrap: wrap;
  & .ant-space-item {
    height: 24px;
  }
`;

const useDefaultOptions = () => {
  // * if we get to calling this, we already fetched the initial data, so the value is accurate
  // const showAllOption = useAllOption();
  useAllOption();

  let defOrgs = []
  let defPros = []

  return { defOrgs, defPros };
};

const HeaderFiltersContent = () => {
  const [filteredProjectOptions, setFilteredProjectOptions] = useState([]);

  const { setOrganization, setProject } = useGlobalFiltersAPICtx();
  const { organization, project } = useGlobalFiltersCtx();

  const { defOrgs, defPros } = useDefaultOptions();

  const organizationsOptions = useOrganizations();
  const projectsOptions = useProjects();
  const organizationsList = useOrganizationsList();

  const updateTitle = React.useCallback(
    (organizationVal: string, projectVal: string) => {
      changeTitle(
        [...defOrgs, ...organizationsOptions].find((s) => s.value === organizationVal)?.label || '',
        [...defPros, ...projectsOptions].find((c) => c.value === projectVal)?.label || ''
      );
    },
    [defOrgs, defPros, organizationsOptions, projectsOptions]
  );

  const onOrganizationChange = React.useCallback(
    (value) => {
      const newValue = String(value || '');
      setOrganization(newValue);
      if(newValue.length !== 0) {
        const filteredProjectOptions = projectsOptions
          .filter(projectOption => organizationsList[newValue].includes(projectOption.value));

        setFilteredProjectOptions(
          filteredProjectOptions
        );
        setProject(
          filteredProjectOptions.find(
            projectOption => projectOption.value && organizationsList[newValue].includes(projectOption.value)
          )?.value
        );
      } else {
        setFilteredProjectOptions(projectsOptions);
      }
      updateTitle(newValue, project);
    },
    [project, setOrganization, updateTitle, organizationsList, projectsOptions, setProject]
  );

  const onProjectChange = React.useCallback(
    (value) => {
      const newValue = String(value || '');
      setProject(newValue);
      updateTitle(organization, newValue);
    },
    [organization, setProject, updateTitle]
  );

  useOnMount(() => {
    updateTitle(organization, project);
    if(Object.keys(organizationsList).length === 0) {
      setOrganization('');
      setProject('');
      return;
    }

    if(!organizationsList.hasOwnProperty(organization)){
      let firstOrganization = Object.keys(organizationsList)[0]

      setOrganization(firstOrganization)

      if(organizationsList[firstOrganization].length > 0){
       let firstProject = organizationsList[firstOrganization][0]
       setProject(firstProject)
      } else {
        setProject('')
      }
    }

    if(organization.length !== 0){

      if(organization in organizationsList){
        setFilteredProjectOptions(
          projectsOptions.filter(projectOption => organizationsList[organization].includes(projectOption.value))
        );

        if(!organizationsList[organization].includes(project)){
          setProject(
            projectsOptions.find(
              projectOption => projectOption.value && organizationsList[organization].includes(projectOption.value)
            )?.value
          );
        }
      }
    } else {
      setFilteredProjectOptions(projectsOptions);
    }
  });

  useEffect(() => {
    if(organization){
      if(organization in organizationsList){
         setFilteredProjectOptions(
          projectsOptions.filter(
            projectOption => organizationsList[organization].includes(projectOption.value)
          )
        )
      }
    } else if(organization === '') {
      setProject('')
    }
    // eslint-disable-next-line
  }, [organization]);

  let projectValue = null;
  let organizationValue = null;
  if(filteredProjectOptions.find(option => option.value === project)){
    projectValue = project;
  } else {
    projectValue = null;
  }

  const filteredOrganizationsOptions = [...defOrgs, ...organizationsOptions]

  if(filteredOrganizationsOptions.find(option => option.value === organization)){
    organizationValue = organization;
  } else {
    organizationValue = null;
  }

  return (
    <StyledSpace>
      <div>
        <Text>
          <Trans>Organization</Trans>:{' '}
        </Text>
        <StyledSelect
          showSearch
          id="conn"
          defaultValue={organizationValue}
          value={organizationValue}
          disabled={filteredOrganizationsOptions.length <= 1}
          status={organization.length === 0 ? 'error' : null}
          placeholder={filteredOrganizationsOptions.length === 0 || organization.length === 0 ? t`No organizations were found` : ''}
          options={filteredOrganizationsOptions}
          onChange={onOrganizationChange}
          style={{ minWidth: '200px' }}
          filterOption={false}
        />
      </div>

      <div>
        <Text>
          <Trans>Project</Trans>:{' '}
        </Text>
        <StyledSelect
          showSearch
          id="web"
          defaultValue={projectValue}
          value={projectValue}
          options={[...defPros, ...filteredProjectOptions]}
          status={filteredProjectOptions.length === 0 || !projectValue ? 'error' : null}
          disabled={filteredProjectOptions.length <= 1}
          placeholder={filteredProjectOptions.length === 0 || !projectValue? t`No projects were found` : ''}
          onChange={onProjectChange}
          style={{ minWidth: '200px' }}
          filterOption={false}
        />
      </div>
    </StyledSpace>
  );
};

const HeaderFilters = () => {
  const { isLoading, isError } = useInitialDataCtx();

  if (isError) {
    return (
      <Typography.Text style={{ color: theme.colors.error.main }}>
        <Trans>An unexpected error has occurred.</Trans>
      </Typography.Text>
    );
  }

  if (isLoading) {
    return <Spin size="small" />;
  }

  return <HeaderFiltersContent />;
};

export default HeaderFilters;
