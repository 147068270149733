import React from 'react';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

const MenuIcon = (props: {
  onClick: () => void;
  collapsed: boolean;
  absolutePosition?: boolean;
}) => {
  const { collapsed, onClick, absolutePosition = false } = props;

  return (
    <>
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: 'trigger',
        onClick,
        style: {
          zIndex: 1,
          background: '#fff',
          padding: '7px',
          borderRadius: '50%',
          border: '1px solid #f0f0f0',

          ...(absolutePosition ? { position: 'absolute', right: '-14px', top: '5px' } : {}),
        },
      })}
    </>
  );
};

export default MenuIcon;
