import { noAuthURLs, STATUS } from 'api/index';
import { post } from 'app/axiosSetup';
import { History } from 'history';

export const handleLogout = (history: History) => {
  post(noAuthURLs.logout)
    .then(function (response) {
      if (response.status === STATUS.OK) {
        history.replace('/login');
      }
    })
    .catch(function (error) {
      console.error(error);
    });
};

export const handleLogin = async (
  history: History,
  payload: { username: string; password: string }
) => {
  try {
    const response = await post(noAuthURLs.login, payload);
    if (response.status === STATUS.OK) {
      history.push('/');
    }
  } catch (error) {
    const err = error.response ? error.response.data : error;
    console.error({ err });
    return err;
  }
  return null;
};
