import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import * as qs from 'query-string';

import { get } from 'app/axiosSetup';
import { useAuthCtx } from 'context/Auth';
import { useGlobalFiltersAPICtx } from 'context/GlobalFilters';
import { getUsableValue } from './initial.utils';

export type TInitialPayload = {
  logged_user: { id: number; email: string; groups: Array<number> };
  choices: {
    organization: { [key: string]: string };
    project: { [key: string]: string };
    sender: { [key: string]: string };
    receiver: { [key: string]: string };
  };
  organizations_list: {
    [key: string]: Array<string>
  },
  permissions: {
    display: {
      choice_all: boolean;
    };
  };
  user_preferences: {
    default_project: string;
    default_organization: string;
  };
};

export type TInitialDataCtx = {
  data: TInitialPayload;
  isLoading: boolean;
  isError: boolean;
};

const initialState = {};

const InitialDataContext = React.createContext<TInitialDataCtx>(initialState as TInitialDataCtx);

export const InitialDataProvider = ({ children }) => {
  const { user } = useAuthCtx();
  const { setState } = useGlobalFiltersAPICtx();

  const { data, isLoading, isError } = useQuery(
    [`initial-data`],
    async () => await get<TInitialPayload>(`api/data/initial-data/`),
    {
      enabled: !!user,
      refetchOnMount: 'always',
      retry: false,
      refetchInterval: 0,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        // * setting default filters

        // * check if there's not a wrong search param value
        const searchParamsOrg = qs.parse(window.location.search).organization as string;
        const searchParamsPro = qs.parse(window.location.search).project as string;
        const usableSearchOrg = getUsableValue(data.choices.organization, searchParamsOrg);
        const usableSearchPro = getUsableValue(data.choices.project, searchParamsPro);

        let pro = usableSearchPro || data?.user_preferences?.default_project
        let org = usableSearchOrg || data?.user_preferences?.default_organization

        if(!data.organizations_list.hasOwnProperty(org)){
          if(Object.keys(data.organizations_list).length > 0){
            let firstOrg = Object.keys(data.organizations_list)[0];

            org = firstOrg;
            pro = data.organizations_list[firstOrg].length > 0 ? data.organizations_list[firstOrg][0] : '';
          } else {
            org = '';
            pro = '';
          }
        } else {
          if(!data.organizations_list[org].includes(pro)){
            pro = data.organizations_list[org].length > 0 ? data.organizations_list[org][0] : '';
          }
        }

        const newState = {
          pro: pro || '',
          org: org || '',
        };

        setState(newState);
      },
    }
  );

  return (
    <InitialDataContext.Provider
      value={{
        data,
        isLoading,
        isError,
      }}
    >
      {children}
    </InitialDataContext.Provider>
  );
};

export const useInitialDataCtx = () => {
  return React.useContext(InitialDataContext);
};

export const useOrganizations = () => {
  const { data, isLoading, isError } = useInitialDataCtx();

  if (isLoading || isError) return [];

  const organization = data.choices.organization;
  const result = Object.keys(organization).map((k) => ({ value: k, label: organization[k] }));

  return result;
};

export const useOrganizationsList = () => {
  const { data } = useInitialDataCtx();
  return data.organizations_list;
}

export const useProjects = () => {
  const { data, isLoading, isError } = useInitialDataCtx();

  if (isLoading || isError) return [];

  const project = data.choices.project;
  const result = Object.keys(project).map((k) => ({ value: k, label: project[k] }));

  return result;
};

export const useReceivers = () => {
  const { data, isLoading, isError } = useInitialDataCtx();

  if (isLoading || isError) return [];

  const receiver = data.choices.receiver;
  const result = Object.keys(receiver).map((k) => ({ value: k, text: receiver[k] }));

  return result;
};

export const useSenders = () => {
  const { data, isLoading, isError } = useInitialDataCtx();

  if (isLoading || isError) return [];

  const sender = data.choices.sender;
  const result = Object.keys(sender).map((k) => ({ value: k, text: sender[k] }));

  return result;
};

export const useAllOption = () => {
  const { data, isLoading, isError } = useInitialDataCtx();

  if (isLoading || isError) return false;

  const show = !!data.permissions?.display?.choice_all;

  return show;
};
