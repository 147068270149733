import { LOGIN, LOGOUT } from './authActions';

export const authReducer = (
  state: { user: string; admin: boolean },
  action: { type: string; payload: { user: string; admin: boolean } }
) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        user: action?.payload?.user || '',
        admin: Boolean(action?.payload?.admin),
      };
    case LOGOUT:
      return {
        ...state,
        user: '',
        admin: false,
      };
    default:
      return state;
  }
};
