import * as React from 'react';
import { Message, Severity } from 'types';
import { SET_MESSAGE } from './messageActions';
import { messageReducer } from './messageReducer';

type TMessageCtx = {
  message: Message;
  setMessage: (type: Severity, text: string) => void;
  clearMessage: () => void;
};

const initialState = {
  message: {
    type: undefined,
    text: '',
  },
};

const MessageContext = React.createContext<TMessageCtx>(initialState as TMessageCtx);

export const MessageProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(messageReducer, initialState);

  function setMessage(type: Severity, text: string) {
    dispatch({
      type: SET_MESSAGE,
      payload: {
        type,
        text,
      },
    });
  }

  function clearMessage() {
    setMessage(undefined, '');
  }

  return (
    <MessageContext.Provider
      value={{
        message: state.message,
        setMessage,
        clearMessage,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export const useMessageCtx = () => {
  return React.useContext(MessageContext);
};
