import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { get } from './axiosSetup';
import { useAuthCtx } from 'context/Auth';
import { noAuthURLs } from 'api';
import { Spin } from 'antd';
import { CenterWrapper } from 'shared/generalStyles';

type LocalProps = {
  component: () => JSX.Element;
  path: string;
  exact?: boolean;
};

const useAuthUser = () => {
  const [isCheckingUser, setIsCheckingUser] = useState(true);
  const [isLogged, setIsLogged] = useState(false);
  const { setUser } = useAuthCtx();

  useEffect(() => {
    get<{ email: string; admin: boolean }>(noAuthURLs.checkUser, {})
      .then((data) => {
        if (data.email) {
          setUser(data.email, data.admin);
          setIsLogged(true);
          setIsCheckingUser(false);
        }
      })
      .catch(function () {
        setIsCheckingUser(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isCheckingUser, isLogged };
};

const PrivateRoute: React.FC<LocalProps> = (props) => {
  const { component: Component, path, exact } = props;

  const { isCheckingUser, isLogged } = useAuthUser();

  if (isCheckingUser) {
    return (
      <CenterWrapper>
        <Spin size="large" />
      </CenterWrapper>
    );
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={(_props) => (isLogged ? <Component /> : <Redirect to="/login" />)}
    />
  );
};

export default PrivateRoute;
