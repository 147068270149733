import React from 'react';
import Dashboard from 'feature.report/components/Dashboard';
import { DashboardProvider } from 'context/Dashboard';

const Report = () => {
  return (
    <DashboardProvider>
      <React.Fragment>
        <Dashboard />
      </React.Fragment>
    </DashboardProvider>
  );
};

export default Report;
