import { createGlobalStyle, ThemeProps } from 'styled-components';

export const theme = {
  fonts: {
    basic: `"Poppins", sans-serif`,
    accent: `"Varela", sans-serif`,
  },
  colors: {
    text: '#0c0a21',

    primary: { main: '#4963fe', text: '#fff' },
    secondary: { main: '#45D470', light: '#4EED7E', text: '#fff' },

    scrollBar: { track: '#cecece', thumb: '#888', thumbHover: '#555' },

    success: { main: '#45D470', light: '#ebf5ee' },
    error: { main: '#fe4955', light: '#faedef', text: '#fff' },
    warning: { main: '#fe9d49', light: '#fffbe6'},
  },
};

export type MainThemeProps = ThemeProps<typeof theme>;
export const GlobalStyle = createGlobalStyle<MainThemeProps>`
  body {
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.basic};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *,
  *::after,
  *::before { margin: 0; padding: 0; box-sizing: border-box; }

  h1, h2, h3, h4, h5, h6 { margin: 0; }
  a { color: ${({ theme }) => theme.colors.secondary.main} }
  a:hover { color: ${({ theme }) => theme.colors.secondary.light} }

  input,
  textarea,
  button {
    font-family: ${({ theme }) => theme.fonts.basic};
  }

  .main {
    padding: 70px 0 20px;
    min-height: calc(100vh - 50px);
  }
`;
