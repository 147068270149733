import axios from 'axios';
import { LooseObject } from '../types';

const customAxios = axios.create({
  baseURL: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? '' : '',
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN',
});

export default customAxios;

export const get = async <T>(path: string, params: LooseObject = {}): Promise<T> => {
  const { data } = await customAxios.get(path, params);

  return data;
};

export const post = async <TPayload, TResult>(
  path: string,
  payload?: TPayload
): Promise<{ status: number; data: TResult }> => {
  const { status, data } = await customAxios.post<TResult>(path, payload);

  return { status, data };
};

export const put = async <TPayload, TResult>(
  path: string,
  payload?: TPayload
): Promise<{ status: number; data: TResult }> => {
  const { status, data } = await customAxios.put<TResult>(path, payload);

  return { status, data };
};

export const destroy = async <TPayload, TResult>(
  path: string,
  payload?: TPayload
): Promise<{ status: number; data: TResult }> => {
  const { status, data } = await customAxios.delete<TResult>(path, payload);

  return { status, data };
};