import { t } from '@lingui/macro';

export const HEADER_HEIGHT = '77px';

export const FILTER_TEXT_PADDING = '5px 10px 5px 5px';
export const FILTER_SELECT_PADDING = '2px 10px 2px 5px';

export const FILTER_TEXT_SIZE = '12px';

export const ALL_SITES_OPTION = {
  id: null,
  code: '',
  name: t`All`,
  value: '',
  label: t`All`,
};

export const DEFAULT_INTERVAL = 'manual';
