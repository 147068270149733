import type { QueueRecordState } from 'types';

export const getStateIdx = (value: QueueRecordState | ''): number => {
  switch (value) {
    case 'failed':
      return 1;
    case 'processed':
      return 2;
    case 'processing':
      return 3;
    case 'redundant':
      return 4;
    default:
      return 0;
  }
};

export const getStateType = (value: number): QueueRecordState | '' => {
  switch (value) {
    case 1:
      return 'failed';
    case 2:
      return 'processed';
    case 3:
      return 'processing';
    case 4:
      return 'redundant';
    default:
      return '';
  }
};
