import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Row } from '../../../types';
import { ColumnFilters } from './Filters';
import * as qs from 'query-string';
import * as React from 'react';
import { ActionType } from '@ant-design/pro-components';
import { SaveOutlined, EditOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Button } from 'antd';

export const scrollProps = {
  //y: `calc(100vh - 24px - 39px - 64px - 16px - 8px - 32px)`,
  //x: `calc(100vw - 50px - 24px - 24px)`,
  y: '500px',
  x: '600px',
  scrollToFirstRowOnChange: true,
}

export const rowClassNameProps = (record, _index) => {
  if (record.new_row) {
    return 'new-row';
  }

  return '';
}

export const useDefaultPage = (actionRef: React.MutableRefObject<ActionType>) => {
  // * hacky solution b/c defaultCurrent in pagination does not work
  const defaultCurrent = Number(qs.parse(window.location.search).page || 1);

  React.useEffect(() => {
    actionRef.current.setPageInfo({ current: defaultCurrent });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export const getInitialColFilters = (): ColumnFilters => {
  const result = {};
  const parsedSearch = qs.parse(window.location.search);

  Object.keys(parsedSearch).forEach((key) => {
    const value = parsedSearch[key];

    if (key !== 'ordering' && key !== 'organization' && key !== 'project') {
      if (key.startsWith('time') && typeof value === 'string') {
        const [start, end] = value.split(' - ');
        result[key] = [start, end];
      } else {
        result[key] = value;
      }
    }
  });

  return result;
};

export const processFilters = (filters: Record<string, FilterValue>) => {
  const result = {};
  Object.keys(filters).forEach((key) => {
    const value = filters[key];
    if (value) {
      const joiner = key.startsWith('time_') ? ' - ' : ',';
      result[key] = value.join(joiner);
    }
  });
  return result;
};

export const processSorter = (sorter: SorterResult<Row>) => {
  const getSign = (order: string) => (order === 'ascend' ? '' : '-');
  const sorting = sorter?.column ? { ordering: `${getSign(sorter.order)}${sorter.columnKey}` } : {};
  return sorting;
};

export const updateUrl = (parameters: object) => {
  const stringified = qs.stringify(parameters);
  const currentURL = window.location.pathname;
  if(Object.keys(parameters).length)
    window.history.replaceState({}, '', `${currentURL}?${stringified}`);
  else
    window.history.replaceState({}, '', `${currentURL}`);
}

const EditorButton = styled(Button)`
  border-radius: 50%;
  width: 35px;
  padding: 0;
  height: 35px;
  font-size: 18px;
`;

interface EditorButtonProps {
  onClick?: (...args: any[]) => void
}

export const SaveButton = ({onClick}: EditorButtonProps) => (<>
  <EditorButton type={'primary'} onClick={onClick}><SaveOutlined /></EditorButton>
</>);

export const EditButton = ({onClick}: EditorButtonProps) => (<>
  <EditorButton type={'primary'} onClick={onClick}><EditOutlined /></EditorButton>
</>)

export const CancelButton = ({onClick}: EditorButtonProps) => (
  <EditorButton type={'primary'} onClick={onClick}><CloseOutlined /></EditorButton>
);

export const DeleteButton = ({onClick}: EditorButtonProps) => (
  <EditorButton type={'primary'} onClick={onClick} danger><DeleteOutlined /></EditorButton>
);