import { Button, Form, Input } from 'antd';
import breakpoints from 'shared/breakpoints';
import { theme as globalTheme } from 'shared/theme';
import styled from 'styled-components';

export const StyledLoginForm = styled(Form)`
  @media only screen and (${breakpoints.device.xs}) {
    margin: auto auto 0 auto;
    height: 100%;
    width: 100%;
    padding: 60px 20px 20px 20px;
    border-radius: 10px 10px 0 0;
  }
  @media only screen and (${breakpoints.device.sm}) {
    margin: 0 auto;
    height: auto;
    width: 75%;
    padding: 0px;
    border-radius: 0px;
  }

  background-color: ${globalTheme.colors.primary.text} !important;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export const LoginField = styled(Input)`
  @media only screen and (${breakpoints.device.xs}) {
    width: 100%;
    margin: 10px 0px !important;
  }
  @media only screen and (${breakpoints.device.sm}) {
    width: 40ch;
    margin: 10px !important;
  }

  background-color: #f1f1f1 !important;
  border-radius: 4px;
  font-family: 'Poppins', Sans-serif;

  & input {
    font-family: 'Poppins', Sans-serif;
    font-size: 15px;
    color: #3d4459;
    background-color: #f1f1f1 !important;
  }

  & input:-webkit-autofill,
  & input:-webkit-autofill:hover,
  & input:-webkit-autofill:focus,
  & input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f1f1f1 inset !important;
  }
`;

export const FormItem = styled(Form.Item)`
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-family: 'Poppins', Sans-serif !important;

  @media (max-width: 575px) {
    display: block;
    & .ant-form-item-label {
      flex: inherit !important;
      flex-grow: 0 !important;
      max-width: 100% !important;
    }
  }

  & .ant-form-item-control-input-content {
    display: flex;
  }
`;

export const Wrapper = styled.div`
  @media only screen and (${breakpoints.device.xs}) {
    border-radius: 10px 10px 0 0;
    padding: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    background-color: transparent !important;
  }

  @media only screen and (${breakpoints.device.sm}) {
    padding: 60px 20px 20px 20px;

    width: 500px;
    height: auto;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 4px;
    background-color: ${globalTheme.colors.primary.text} !important;
  }

  margin: 0 auto;
  padding: 20px;
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  & .ant-form-item-control {
    width: 100%;
  }
`;

export const SubmitBtn = styled(Button)`
  @media only screen and (${breakpoints.device.xs}) {
    width: 100%;
    border-radius: 20px !important;
  }
  @media only screen and (${breakpoints.device.sm}) {
    width: auto;
    border-radius: 4px !important;
  }

  margin: 10px 0px 10px auto !important;
  font-family: 'Varela', Sans-serif !important;
`;

export const Separator = styled.hr`
  @media only screen and (${breakpoints.device.xs}) {
    display: none;
  }
  @media only screen and (${breakpoints.device.sm}) {
    display: block;
    border: 0;
    height: 2px;
    background-image: linear-gradient(to right, transparent, #ccc, transparent);
    margin: 20px 0;
  }
`;

export const Head = styled.div`
  @media only screen and (${breakpoints.device.xs}) {
    width: 100%;
    height: 100%;
    & > .logo-md {
      display: none;
    }
    & > .logo-xs {
      display: block;
    }
  }
  @media only screen and (${breakpoints.device.sm}) {
    width: 40ch;
    height: auto;
    & > .logo-md {
      display: block;
    }
    & > .logo-xs {
      display: none;
    }
  }

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  & img {
    width: 50%;
  }
`;
