import React from 'react';

import Home from 'feature.home';

const AppHome = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default AppHome;
