import { t } from '@lingui/macro';
import { /*handleLogin,*/ handleLogout } from 'feature.auth/data/auth';
import { useMessageCtx } from 'context/Message';
import * as React from 'react';
import { useHistory } from 'react-router';
import { LOGIN } from './authActions';
import { authReducer } from './authReducer';
import { useQueryClient } from '@tanstack/react-query';
import { useGlobalFiltersAPICtx } from "../GlobalFilters";
import { post } from '../../app/axiosSetup';
import { noAuthURLs, STATUS } from '../../api';

export type TAuthCtx = {
  user: string;
  admin: boolean;
  login: (user: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  setUser: (user: string, admin?: boolean) => void;
};

const initialState = {
  user: '',
  admin: false,
};

const AuthContext = React.createContext<TAuthCtx>(initialState as TAuthCtx);

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const { setMessage, clearMessage } = useMessageCtx();
  const { clearContext } = useGlobalFiltersAPICtx();

  const [state, dispatch] = React.useReducer(authReducer, initialState);

  // Get QueryClient from the context
  const queryClient = useQueryClient();

  const setUser = (user: string, admin = false) => {
    dispatch({
      type: LOGIN,
      payload: {
        user,
        admin,
      },
    });
  };

  const login = async (username: string, password: string) => {
    /*const result = await handleLogin(history, { username, password });
    if (result) {
      setMessage('error', t`Username and password do not match`);
    } else {
      clearMessage();
      setUser(username);
    }*/

    try {
      const response = await post(noAuthURLs.login, { username, password });
      if (response.status === STATUS.OK) {
        clearMessage();
        setUser(username);
        history.push('/');
      }
    } catch (error) {
      const err = error.response ? error.response.data : error;
      console.error({ err });
      setMessage('error', t`Username and password do not match`);
    }
    queryClient.invalidateQueries({ queryKey: [`initial-data`] });
  };

  const logout = async () => {
    queryClient.clear()
    clearContext()
    setUser('')
    handleLogout(history);
  };

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        admin: state.admin,
        login,
        logout,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthCtx = () => {
  return React.useContext(AuthContext);
};
