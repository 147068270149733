import React from 'react';
import { useIntervalCtx } from 'context/Refresh';
import { useWebSocketCtx } from 'context/WebSocket';
import { DEFAULT_INTERVAL } from 'utils/constants';

export type Interval =
  | 'manual'
  | 'realTime'
  | 'every-30-sec'
  | 'every-1-minute'
  | 'every-5-minute'
  | 'every-15-minute';

const useUpdateInterval = (refetchData: () => Promise<void>) => {
  const [updateInterval, setUpdateInterval] = React.useState<Interval>(DEFAULT_INTERVAL);
  const { connectWebSocket, disconnectWebSocket } = useWebSocketCtx();
  const { startInterval, stopInterval } = useIntervalCtx();

  const stopRefresh = () => {
    stopInterval();
    disconnectWebSocket();
  };

  const changeInterval = React.useCallback(
    (interval: Exclude<Interval, 'realTime'>) => {
      if (interval === 'manual') {
        refetchData();
        return;
      }

      const splitData = interval.split('-');
      const type = splitData[2];
      let every = Number(splitData[1]);
      if (type === 'sec') {
        every = every * 1000;
      }
      if (type === 'minute') {
        every = every * 60000;
      }
      startInterval(refetchData, every);
    },
    [refetchData, startInterval]
  );
  const onUpdateInterval = async (interval: Interval) => {
    setUpdateInterval(interval);

    stopRefresh();

    if (interval === 'manual') {
      await refetchData();
    } else if (interval === 'realTime') {
      connectWebSocket();
    } else {
      changeInterval(interval);
    }
  };

  React.useEffect(() => {
    changeInterval(DEFAULT_INTERVAL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    updateInterval,
    onUpdateInterval,
  };
};

export default useUpdateInterval;
