import { CLEAR_CONTEXT, SET_ORGANIZATION, SET_PROJECT, SET_STATE } from './actions';
import { TGlobalFiltersCtx } from './types';

type TActionsModule = typeof import('./actions');

type TActions = TActionsModule[keyof TActionsModule];

export const reducer = (
  state: TGlobalFiltersCtx,
  action:
    | { type: Exclude<TActions, typeof SET_STATE>; payload: string }
    | { type: typeof SET_STATE; payload: { pro: string; org: string } }
    | { type: typeof CLEAR_CONTEXT; }
): TGlobalFiltersCtx => {
  switch (action.type) {
    case SET_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    case SET_PROJECT:
      return {
        ...state,
        project: action.payload,
      };
    case SET_STATE:
      return {
        ...state,
        project: action.payload.pro,
        organization: action.payload.org,
      };
    case CLEAR_CONTEXT:
      return {
        project: null,
        organization: null
      };
    default:
      return state;
  }
};
