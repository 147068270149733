import moment from 'moment';
import ReactDOMServer from 'react-dom/server'
import { theme } from 'shared/theme';
import { format, startOfYear, endOfYear } from 'date-fns';
import { resources } from 'api';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { get } from 'app/axiosSetup';
import React, {useEffect, useState} from 'react';
import ReactECharts, {EChartsOption} from 'echarts-for-react';
import { Form, Space, DatePicker, Spin } from 'antd';
import ChartSettings from 'feature.report/components/ChartSettings';
import { ChartWrapper, WidgetWrapper } from 'feature.report/components/style';
import { useGlobalFiltersCtx } from 'context/GlobalFilters';
import { dateFnsLocales, getMonths, getWeekDays } from 'shared/dateFns';
import { useDashboardCtx } from 'context/Dashboard';

const defaultOptions: EChartsOption = {
  title: {
    top: 0,
    left: 'center',
    text: t`This year in total`
  },
  tooltip: {
    renderMode: 'html',
    formatter: function(params) {
      return ReactDOMServer.renderToString(
        <div>
          <div>
            {params.data.value[0]}
          </div>
          <div style={{color: theme.colors.secondary.main}}>
            {t`Successful`}: {params.data.value[2]}
          </div>
          <div style={{color: theme.colors.error.main}}>
            {t`Failed`}: {params.data.value[3]}
          </div>
        </div>
      );
    }
  },
  visualMap: {
    min: -1000,
    max: 1000,
    show: false,
    dimension: 1,
    type: 'piecewise',
     inRange: {
      color: []
    },
  },
  calendar: {
    top: 50,
    left: 30,
    right: 30,
    cellSize: [15, 15],
    range: null,
    monthLabel: {
      nameMap: 'en'
    },
    dayLabel: {
      firstDay: 1,
      nameMap: 'en'
    },
    yearLabel: {
      show: false,
    },
    itemStyle: {
      borderWidth: 0.1,
    },
  },
  series: {
    type: 'heatmap',
    coordinateSystem: 'calendar',
    //fill
    data: []
  }
};

function calcItemColor(successfulCount: number = 0, failedCount){
  if(successfulCount > failedCount){
    if(failedCount === 0 || successfulCount / failedCount > 2)
      return theme.colors.secondary.main;
    else
      return theme.colors.secondary.light;
  } else if (failedCount > successfulCount) {
    if(successfulCount === 0 || failedCount / successfulCount > 2)
      return theme.colors.error.main;
    else
      return '#ff8e94';
  } else if(failedCount === successfulCount){
    return theme.colors.warning.main;
  }
}

const processServerData = (data: Array<any>) => {
  let series = {};

  let groupByDate = {};
  data.forEach((item) => {
    let date = format(new Date(item['time_since']), 'RRRR-MM-dd');
    if(!groupByDate[date]) groupByDate[date] = {
      successfulCount: 0,
      failedCount: 0,
      timeSince: item['time_since']
    };

    groupByDate[date].successfulCount += item['successful_count'];
    groupByDate[date].failedCount += item['failed_count'];
  })

  let seriesData = []
  for (let date in groupByDate){
    let count = groupByDate[date]['failedCount'] > groupByDate[date]['successfulCount']
      ? -groupByDate[date]['failedCount']
      : groupByDate[date]['successfulCount'];
    seriesData.push({
      name: format(new Date(groupByDate[date]['timeSince']), 'RRRR-MM-dd'),
      value: [
        format(new Date(groupByDate[date]['timeSince']), 'RRRR-MM-dd'),
        count,
        groupByDate[date]['successfulCount'],
        groupByDate[date]['failedCount'],
      ],
      itemStyle: {
        color: calcItemColor(groupByDate[date]['successfulCount'], groupByDate[date]['failedCount'])
      }
    });
  }

  series = {
    type: 'heatmap',
    coordinateSystem: 'calendar',
    data: seriesData
  };

  return series;
}

const getMaxMinValues = (data: Array<number>): {min: number, max: number} => {
  const output = {
    max: 100,
    min: Infinity
  };

  output.max = Math.max(...data);
  output.min = Math.min(...data);

  if(output.max === output.min) {
    output.min = 0;
  }

  return output;
}

function CalendarHeatmap(){
  const {i18n} = useLingui();
  const {project, organization} = useGlobalFiltersCtx();
  const {statType, agenda} = useDashboardCtx();
  const statUrls = {
    'all': resources.statsAllDaily,
    'operation': resources.statsOperationDaily,
    'event': resources.statsEventDaily,
  }

  const [calendarYear, setCalendarYear] = useState<Date>(new Date());
  const [chartOptions, setChartOptions] = useState<EChartsOption>({...defaultOptions});
  const [chartIsReady, setChartIsReady] = useState<boolean>(false);

  useEffect(() => {
    if(calendarYear !== null){
      setChartIsReady(false);
      let params = {
        time_since__gte: startOfYear(calendarYear),
        time_since__lte: endOfYear(calendarYear),
      }

      if(organization) params['company__company_name'] = organization
      if(project) params['project__name'] = project
      if(agenda) params['object_agenda'] = agenda

      get(statUrls[statType], {params: params})
        .then((response: object[]) => {
          let seriesData = processServerData(response);
          setChartOptions(prevState => {
            prevState.title.text = t`This year in total`;
            prevState.series = seriesData;
            prevState.calendar.range = calendarYear.getFullYear();
            prevState.calendar.monthLabel.nameMap = getMonths(
              {
                locale: dateFnsLocales[i18n.locale],
                start: startOfYear(new Date()),
                end: endOfYear(new Date())
              }
            );
            prevState.calendar.dayLabel.nameMap = getWeekDays({locale: dateFnsLocales[i18n.locale]});

            const {max, min} = getMaxMinValues(prevState.series.data.map(item => item.value[1]));

            prevState.visualMap.min = min;
            prevState.visualMap.max = max;

            return prevState
          })
          setTimeout(() => setChartIsReady(true), 1000);

        })
    }
    // eslint-disable-next-line
  }, [organization, project, agenda, calendarYear, i18n, statType]);

  return (
    <WidgetWrapper>
      <ChartSettings>
        <Space>
          <Form.Item label={t`Year`}>
            <DatePicker
                defaultValue={moment(new Date())}
                onChange={(value) => setCalendarYear(value.toDate())} picker="year"
                allowClear={false}
            />
          </Form.Item>
        </Space>
      </ChartSettings>

      {
        chartIsReady
        ? <ChartWrapper><ReactECharts option={chartOptions} /></ChartWrapper>
          : <ChartWrapper><Spin/></ChartWrapper>
      }
    </WidgetWrapper>
  );
}

export default CalendarHeatmap;
