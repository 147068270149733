import React from 'react';

const SelectedContext = React.createContext({ selected: [] });
const SelectedAPIContext = React.createContext(
  {} as { setSelected: React.Dispatch<React.SetStateAction<React.Key[]>> }
);

export const SelectedProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [selected, setSelected] = React.useState<React.Key[]>([]);

  const API = React.useMemo(
    () => ({
      setSelected,
    }),
    []
  );

  return (
    <SelectedAPIContext.Provider value={API}>
      <SelectedContext.Provider value={{ selected }}>{children}</SelectedContext.Provider>
    </SelectedAPIContext.Provider>
  );
};

export const useSelected = () => {
  return React.useContext(SelectedContext);
};

export const useSelectedAPI = () => {
  return React.useContext(SelectedAPIContext);
};
