import * as React from 'react';
import { Alert, Skeleton } from 'antd';
import { ProDescriptions } from '@ant-design/pro-components';
import { t, Trans } from '@lingui/macro';

import type { AttachmentType , Row } from 'types';
import { get } from 'app/axiosSetup';
import { AcceptedCell } from './SharedCells';
import AdminNote from './AdminNote';
import StateIndicator from './StateIndicator';
import styled from 'styled-components';
import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useLingui } from '@lingui/react';
import { formatDate } from 'utils/date';
import { theme } from 'shared/theme';
import { Link } from 'react-router-dom';
import DataDescription from "./DataDescription";

type FetchedAttributes = {
  data_json: string;
  data_json_pretty: string;
  note_admin: string;
  status_msg: string;
  state: string;
  files: AttachmentType[];
};

const COLUMNS = 2;

const StyledDescription = styled(ProDescriptions)`
  width: 100%;
  background: #fbfbfb;
  .ant-descriptions-item-label {
    font-size: 12px;
    background-color: #eeeeee !important;
  }
  .ant-descriptions-item-content {
    font-size: 12px;
  }
  .ant-descriptions-view {
    height: 100%;
  }
  .ant-descriptions-row {
    border-bottom: 1px solid #cccccc;
    & .ant-descriptions-item-label {
      width: 200px;
    }
  }
  .data-json-pretty.ant-descriptions-item-content {
    padding: 0;
  }
`;

const Note = styled(Alert)`
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 8px;
  border: none;
`;

const ErrorNote = styled(Note)`
  background-color: ${theme.colors.error.light};
  & .anticon-close-circle.ant-alert-icon {
    color: ${theme.colors.error.main};
  }
`;

const SuccessNote = styled(Note)`
  background-color: ${theme.colors.success.light};
  & .anticon-close-circle.ant-alert-icon {
    color: ${theme.colors.success.main};
  }
`;

const WarningNote = styled(Note)`
  background-color: ${theme.colors.warning};
  & .anticon-close-circle.ant-alert-icon {
    color: ${theme.colors.warning.main};
  }
`;

const NoteView = (props: { statusMsg: string; processed: boolean, redundant: boolean }) => {
  const { statusMsg, processed, redundant } = props;

  if (!statusMsg) return null;

  const description = <div dangerouslySetInnerHTML={{ __html: statusMsg }} />;

  if (processed) {
    return <SuccessNote message="" description={description} type="success" showIcon />;
  }
  if (redundant) {
    return <WarningNote message="" description={description} type="warning" showIcon />;
  }

  return <ErrorNote message={t`Error`} description={description} type="error" showIcon />;
};

const CopyButton = (props: { onClick: () => void }) => {
  const [copied, setCopied] = React.useState(false);

  const handleClick = () => {
    props.onClick();

    setCopied(true);

    setTimeout(() => setCopied(false), 2000);
  };

  if (copied) return <CheckOutlined className="ant-typography-copy" />;

  return <CopyOutlined className="ant-typography-copy" onClick={handleClick} />;
};

const RecordDescription = (props: { record: Row }) => {
  const { i18n } = useLingui();
  const { record } = props;
  const queryClient = useQueryClient();

  const unformatted = React.useRef<HTMLDivElement>();

  const { data, isLoading, isError } = useQuery(
    [`record/${record.id}`],
    async () => await get<FetchedAttributes>(`api/data/queue-data/${record.id}`)
  );

  if (isLoading)
    return (
      <div style={{ display: 'flex' }}>
        {Array(COLUMNS)
          .fill(0)
          .map((_, i) => (
            <Skeleton key={i} style={i === COLUMNS - 1 ? {} : { marginRight: '16px' }} />
          ))}
      </div>
    );

  if (isError)
    return (
      <ErrorNote
        message={t`Error`}
        description={t`There has been an error.`}
        type="error"
        showIcon
      />
    );

  const onDataChange = () => {
    queryClient.invalidateQueries({ queryKey: [`record/${record.id}`] });
  }

  return (
    <>
      <NoteView
        processed={record.state === 'processed'}
        redundant={record.state === 'redundant'}
        statusMsg={data.status_msg}
        />
      <div style={{ width: '100%', display: 'flex', alignItems: 'stretch' }}>
        <StyledDescription
          column={COLUMNS}
          title=""
          dataSource={data}
          bordered
          size="small"
          layout="horizontal"
        >
          <ProDescriptions.Item
            //span={COLUMNS / 2}
            label={
              <b>
                <Trans>Data</Trans>{' '}
                <CopyButton
                  onClick={() => {
                    navigator.clipboard.writeText(unformatted.current.innerText);
                  }}
                />
              </b>
            }
            className="data-json-pretty"
            dataIndex="data_json_pretty"
            editable={false}
            render={(_dom, entity, _index, _action) => {
              return (
                <React.Fragment>
                  <DataDescription id={record.id} json={JSON.parse(entity.data_json)} onChange={onDataChange}/>
                </React.Fragment>
              );
            }}
          />
        </StyledDescription>

        <StyledDescription
          column={COLUMNS}
          title=""
          dataSource={data}
          bordered
          size="small"
          layout="horizontal"
        >
          <ProDescriptions.Item
            label={
              <b>
                <Trans>Time created</Trans>
              </b>
            }
            editable={false}
          >
            {formatDate(record.time_created, i18n.locale)}
          </ProDescriptions.Item>
          <ProDescriptions.Item
            label={
              <b>
                <Trans>Time updated</Trans>
              </b>
            }
            editable={false}
          >
            {formatDate(record.time_updated, i18n.locale)}
          </ProDescriptions.Item>
          <ProDescriptions.Item
            label={
              <b>
                <Trans>Time processed</Trans>
              </b>
            }
            editable={false}
          >
            {formatDate(record.time_processed, i18n.locale)}
          </ProDescriptions.Item>
          <ProDescriptions.Item
            editable={false}
            label={
              <b>
                <Trans>Accepted</Trans>
              </b>
            }
            valueType="text"
            render={() => <AcceptedCell accepted={record.accepted} />}
          />
          {record.state && (
            <ProDescriptions.Item
              editable={false}
              valueType="text"
              label={
                <b>
                  <Trans>State</Trans>
                </b>
              }
              render={() => <StateIndicator id={String(record.id)} type={record.state} layout="spread"/>}
            />
          )}
          {[
            { key: 'attempts_number', header: t`Attempts number` },
            { key: 'object_description', header: t`Description` },
            { key: 'object_agenda', header: t`Agenda` },
            { key: 'sender_module', header: t`Sender` },
            { key: 'receiver_modules', header: t`Receivers` },
            { key: 'status', header: t`Status` },
          ].map((r) => {
            const value = record[r.key];
            const isArray = value && Array.isArray(value);
            const output = isArray ? value.join(', ') : value ? value : '-';

            return (
              <ProDescriptions.Item key={r.key} label={<b>{r.header}</b>} editable={false}>
                {output}
              </ProDescriptions.Item>
            );
          })}
          <ProDescriptions.Item
            //span={2}
            label={
              <b>
                <Trans>Admin note</Trans>
              </b>
            }
            valueType="text"
            render={() => <AdminNote initialValue={record.note_admin} id={String(record.id)} />}
          />
          <ProDescriptions.Item
            label={
              <b>
                <Trans>Files</Trans>
              </b>
            }
            valueType="text"
            render={() => <>{record.files && record.files.map(file => <>
              <Link to={file.url} target="_blank" rel="noopener nereffer">{file.url}</Link>
              <br/>
            </>)}</>}
          />
        </StyledDescription>
      </div>

      <div style={{ height: '24px' }} />
    </>
  );
};

export default RecordDescription;
