import * as React from 'react';

type TIntervalCtx = {
  interval: NodeJS.Timeout;
  startInterval: (callback: () => Promise<void>, every: number) => void;
  stopInterval: () => void;
};

const useInterval = () => {
  const [inter, setInter] = React.useState<NodeJS.Timeout>(null);

  const stopInterval = () => {
    clearInterval(inter);
    setInter(null);
  };

  const startInterval = (callback: () => Promise<void>, every: number) => {
    const interval = setInterval(async () => {
      await callback();
    }, every);
    setInter(interval);
  };

  React.useEffect(() => {
    return stopInterval;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { startInterval, stopInterval, interval: inter };
};

const IntervalContext = React.createContext<TIntervalCtx>({} as TIntervalCtx);

export const IntervalProvider = ({ children }) => {
  const value = useInterval();

  return <IntervalContext.Provider value={value}>{children}</IntervalContext.Provider>;
};

export const useIntervalCtx = () => {
  return React.useContext(IntervalContext);
};
