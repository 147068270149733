import React from 'react';

export const useOnMount = (func: Function) => {
  React.useEffect(
    () => {
      func();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
