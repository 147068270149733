const fakeFetchInitialData = async () => {
  const result = {
    logged_user: { id: 4, email: 'standa@whys.dev', groups: [] },
    choices: {
      organization: {
        shopup: 'ShopUp',
      },
      project: {
        digilama: 'Digilama',
      },
      sender: {
        money: 'Money',
        pohoda: 'Pohoda',
      },
      receiver: {
        money: 'Money',
        pohoda: 'Pohoda',
      },
    },
  };
  return new Promise<typeof result>((resolve) => {
    return setTimeout(() => resolve(result), 3000);
  });
};

/**
 *
 * @param data object that has allowed values
 * @param val value function is testing whether is usable
 * @returns allowed or empty string
 */
const getUsableValue = (data: { [key: string]: string }, val: string) => {
  // * check if there's not a wrong search param value
  const canUseValue = Object.keys(data).includes(val);
  const usableVal = canUseValue ? val : '';
  return usableVal;
};

export { fakeFetchInitialData, getUsableValue };
