import SyncAgentLogoWhite from 'assets/logo/SyncAgent_Logo_white.svg';
import SyncAgentLogoPurple from 'assets/logo/SyncAgent_Logo_purple.svg';
import SyncAgentLogoColored from 'assets/logo/SyncAgent_Logo_two-colors.svg';
import SyncAgentLogoMain from 'assets/logo/SyncAgent_Logo_main.svg';
import SyncAgentLogoMainShort from 'assets/logo/SyncAgent_Logo_main_short.svg';

const Logo = (props: { style?: React.CSSProperties }) => {
  return (
    <img
      src={SyncAgentLogoMain}
      alt={`syncAgent`}
      style={{
        height: '45px',
        marginRight: '30px',
        marginTop: '10px',
        ...props.style,
      }}
    />
  );
};

const LogoShort = (props: { style?: React.CSSProperties }) => {
  return (
    <img
      src={SyncAgentLogoMainShort}
      alt={`syncAgent`}
      style={{
        height: '18px',
        marginRight: '30px',
        marginTop: '0px',
        ...props.style,
      }}
    />
  );
};

export {
  SyncAgentLogoWhite,
  SyncAgentLogoPurple,
  SyncAgentLogoColored,
  SyncAgentLogoMain,
  SyncAgentLogoMainShort,
  Logo,
  LogoShort,
};
