import {
  CheckOutlined,
  CloseOutlined,
  SwapLeftOutlined,
  SwapRightOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { theme } from 'shared/theme';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  margin-left: 5px;
`;

export const StyledIconWrapper = styled(Button)`
  max-width: 24px;
  max-height: 24px;
`;

export const StyledProcessedIcon = styled(CheckOutlined)`
  color: ${theme.colors.success.main};
`;

export const StyledUnprocessedIcon = styled(CloseOutlined)`
  color: ${theme.colors.error.main};
`;

export const StyledWarningIcon = styled(WarningOutlined)`
  color: ${theme.colors.warning.main};
`;

const ProcessingIconWrapper = styled.div`
  color: ${theme.colors.warning.main};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .right {
    margin-top: -7px;
    transform: rotateX(180deg);
    animation: rightArrowMove 1s infinite;
  }

  @keyframes rightArrowMove {
    0% {
      transform: rotateX(180deg) translateX(0px);
    }
    50% {
      transform: rotateX(180deg) translateX(2px);
    }
    100% {
      transform: rotateX(180deg) translateX(0px);
    }
  }

  & .left {
    animation: leftArrowMove 1s infinite;
  }

  @keyframes leftArrowMove {
    0% {
      transform: translateX(0px);
    }
    50% {
      transform: translateX(-2px);
    }
    100% {
      transform: translateX(0px);
    }
  }
`;

export const StyledProcessingIcon = () => {
  return (
    <ProcessingIconWrapper>
      <SwapLeftOutlined className="left" />
      <SwapRightOutlined className="right" />
    </ProcessingIconWrapper>
  );
};
