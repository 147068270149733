// TODO: figure out how to add generic typing

export const localStorageAPI = {
  getValue: <T = string>(key: string): T | null => {
    if (!localStorage) {
      return null;
    }
    const stored = localStorage.getItem(key);
    if (!stored) {
      return null;
    }
    try {
      return JSON.parse(stored);
    } catch (e) {
      console.error(e);
    }
    return null;
  },
  setValue: (key: string, value: any): void => {
    if (!localStorage) {
      return;
    }
    let strValue = '';
    try {
      strValue = JSON.stringify(value);
    } catch (e) {
      console.error(e);
    }
    localStorage.setItem(key, strValue);
  },
};
