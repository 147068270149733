import { get } from 'app/axiosSetup';
import { theme } from 'shared/theme';
import { resources } from 'api';
import { t } from '@lingui/macro';
import { format, subDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { Spin } from 'antd';
import { ChartWrapper, WidgetWrapper } from 'feature.report/components/style';
import { useGlobalFiltersCtx } from 'context/GlobalFilters';
import { useLingui } from '@lingui/react';
import { useDashboardCtx } from '../../../context/Dashboard';

const defaultOptions: EChartsOption = {
  title: {
    top: 0,
    left: 'center',
    text: t`In the last month`
  },
  responsive: true,
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {
    top: 25
  },
  grid: {
    left: '6%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      axisLabel: {
        rotate: 50,
      },
      data: [],
    }
  ],
  yAxis: [
    {
      type: 'value'
    }
  ],
  series: []
}

const processServerData = (data: Array<any>) => {
  let series = [];
  let lastMonthDays = generateXaxisData();

  let groupByDate = {};
  data.forEach((item) => {
    let date = format(new Date(item['time_since']), 'RRRR-MM-dd');
    if(!groupByDate[date]) groupByDate[date] = {
      successfulCount: 0,
      failedCount: 0,
      timeSince: item['time_since']
    };

    groupByDate[date].successfulCount += item['successful_count'];
    groupByDate[date].failedCount += item['failed_count'];
  })

  let successfulSeriesData = [];
  let failedSeriesData = [];

  lastMonthDays.forEach((day) => {
    if(groupByDate[day]){
      successfulSeriesData.push(groupByDate[day].successfulCount);
      failedSeriesData.push(groupByDate[day].failedCount);
    } else {
      successfulSeriesData.push(null);
      failedSeriesData.push(null);
    }
  })

  series.push({
    name: t`Successful`,
    type: 'bar',
    data: successfulSeriesData,
    itemStyle: {
      color: theme.colors.secondary.main
    },
    stack: 'stats',
  });

  series.push({
    name: t`Failed`,
    type: 'bar',
    data: failedSeriesData,
    itemStyle: {
      color: theme.colors.error.main
    },
    stack: 'stats',
  });

  return series;
}

const generateXaxisData = () => {
  const axisData = [];
  const now = new Date();

  for(let i = 0; i !== 30; i ++){
    axisData.push(format(subDays(now, i), 'RRRR-MM-dd'));
  }

  return axisData.reverse();
}

function DailyLastMonth(){
  const {i18n} = useLingui();
  const {project, organization} = useGlobalFiltersCtx();
  const {agenda, statType} = useDashboardCtx();
  const statUrls = {
    'all': resources.statsAllDaily,
    'operation': resources.statsOperationDaily,
    'event': resources.statsEventDaily,
  }

  const [chartOptions, setChartOptions] = useState<EChartsOption>(defaultOptions);
  const [chartIsReady, setChartIsReady] = useState<boolean>(false);

  useEffect(() => {
    if(agenda !== null){
      setChartIsReady(false);
      const now = new Date();
      let params = {
        time_since__gte: subDays(now, 30),
        time_since__lte: now,
      }

      if(organization) params['company__company_name'] = organization
      if(project) params['project__name'] = project
      if(agenda) params['object_agenda'] = agenda

      get(statUrls[statType], {params: params})
        .then((response: object[]) => {
          let seriesData = processServerData(response);
          setChartOptions(prevState => {
            prevState.title.text = t`In the last month`
            prevState.series = seriesData
            prevState.xAxis[0].data = generateXaxisData()
            return prevState
          });
          setTimeout(() => setChartIsReady(true), 1000);

        })
    }
    // eslint-disable-next-line
  }, [organization, project, agenda, i18n, statType]);

  return (
    <WidgetWrapper width={'100%'}>
      {
        chartIsReady
        ? <ChartWrapper><ReactECharts option={chartOptions} /></ChartWrapper>
          : <ChartWrapper><Spin/></ChartWrapper>
      }
    </WidgetWrapper>
  );
}

export default DailyLastMonth;
