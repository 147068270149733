import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { theme } from 'shared/theme';
import styled from 'styled-components';

export const CenterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AcceptedCell = ({ accepted }: { accepted: boolean }) => (
  <>
    {accepted ? (
      <CheckOutlined style={{ color: theme.colors.success.main }} />
    ) : (
      <CloseOutlined style={{ color: theme.colors.error.main }} />
    )}
  </>
);
