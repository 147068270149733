export const resources = {
  queueData: '/api/data/queue-data/',
  transformations: '/api/data/transformations/',
  transformationsAgendaKeys: '/api/data/transformations/agenda-keys/',
  transformationsAgenda: '/api/data/transformations/agenda/',
  transformationsKeys: '/api/data/transformations/keys/',
  //connections: '/api/conn/app-connection/',
  statsEventDaily: '/api/stats/reports/event-daily/',
  statsEventWeekly: '/api/stats/reports/event-weekly/',
  statsEventMonthly: '/api/stats/reports/event-monthly/',
  statsOperationDaily: '/api/stats/reports/operation-daily/',
  statsOperationWeekly: '/api/stats/reports/operation-weekly/',
  statsOperationMonthly: '/api/stats/reports/operation-monthly/',
  statsAllDaily: '/api/stats/reports/all-daily/',
  statsAllWeekly: '/api/stats/reports/all-weekly/',
  statsAllMonthly: '/api/stats/reports/all-monthly/',
  statsAgendas: '/api/stats/reports/agenda/',

  manageUwsgi: '/api/runtime/manage-uwsgi',

  connections: '/api/v1/connections/'
};

export const noAuthURLs = {
  logout: '/api/auth/logout/',
  login: '/api/auth/login/',
  checkUser: '/api/auth/whoami/',
};

export const STATUS = {
  OK: 200,
  BAD_REQ: 400,
};
