import * as React from 'react';

type TWebSocketCtx = {
  webSocket: WebSocket;
  connectWebSocket: () => void;
  disconnectWebSocket: () => void;
};

const useWebSocket = (initiallyConnect = false) => {
  const [webSocket, setWebSocket] = React.useState<WebSocket>(null);

  const connectWebSocket = () => {
    const ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
    ws.onopen = (e) => console.info('ws opened on ' + (e.target as any).url);
    ws.onclose = () => console.info('ws closed');
    setWebSocket(ws);
  };

  const disconnectWebSocket = () => {
    if (webSocket) {
      webSocket.close();
      setWebSocket(null);
    }
  };

  // webSocket
  React.useEffect(() => {
    if (initiallyConnect) connectWebSocket();

    return disconnectWebSocket;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { webSocket, connectWebSocket, disconnectWebSocket };
};

const WebSocketContext = React.createContext<TWebSocketCtx>({} as TWebSocketCtx);

export const WebSocketProvider = ({ children }) => {
  const value = useWebSocket();

  return <WebSocketContext.Provider value={value}>{children}</WebSocketContext.Provider>;
};

export const useWebSocketCtx = () => {
  return React.useContext(WebSocketContext);
};
