import type { ProColumns } from '@ant-design/pro-components';
import { Table as AntTable } from 'antd';
import type { FilterValue } from 'antd/lib/table/interface';
import { t } from '@lingui/macro';
import * as qs from 'query-string';

import StateIndicator from '../StateIndicator';
import { AcceptedCell, CenterWrapper } from '../SharedCells';
import { getDateColumnProps, getTextColumnProps } from './Filters';
import { Row } from 'types';
import { formatDate } from 'utils/date';
import { useReceivers, useSenders } from 'context/InitialData';

const sortOpts = (dataIndex: string) => {
  const urlOrdering = qs.parse(window.location.search).ordering;

  let defaultSort = '';

  if (urlOrdering && typeof urlOrdering === 'string') {
    if (urlOrdering[0] === '-') {
      const orderingBy = urlOrdering.slice(1);
      if (orderingBy === dataIndex) {
        defaultSort = 'descend';
      }
    } else {
      if (urlOrdering === dataIndex) {
        defaultSort = 'ascend';
      }
    }
  }

  return {
    sorter: true,
    ...(defaultSort ? { defaultSortOrder: defaultSort as 'ascend' | 'descend' } : {}),
  };
};

const getDefaultValue = (key: keyof Row, defaultVal?: string) => {
  return qs.parse(window.location.search)[key]
    ? { defaultFilteredValue: [qs.parse(window.location.search)[key]] as FilterValue }
    : {};
};

const getTimeDefaultValue = (key: keyof Row) => {
  const urlDate = qs.parse(window.location.search)[key];
  if (urlDate && typeof urlDate === 'string') {
    const [start, end] = urlDate.split(' - ');
    return { defaultFilteredValue: [start, end] };
  }
  return {};
};

interface IFilters {
  receiver: ReturnType<typeof useReceivers>;
  sender: ReturnType<typeof useSenders>;
}

const getColumns = (locale: string, filters: IFilters): ProColumns<Row>[] => [
  AntTable.SELECTION_COLUMN,
  AntTable.EXPAND_COLUMN,
  {
    dataIndex: 'id',
    title: t`Operation ID`,
    valueType: 'text',
    hideInSearch: true,
    width: '9.5%',

    ...getTextColumnProps('id'),
    ...getDefaultValue('id'),
  },
  {
    dataIndex: 'event_id',
    title: t`Event ID`,
    valueType: 'text',
    hideInSearch: true,
    width: '9.5%',

    ...getTextColumnProps('event_id'),
    ...getDefaultValue('event_id'),
  },
  {
    dataIndex: 'content_id',
    title: t`Content ID`,
    hideInSearch: true,
    width: '10.5%',
    ...getTextColumnProps('object_description'),
    ...getDefaultValue('object_description'),
  },
  {
    dataIndex: 'project',
    title: t`Project`,
    hideInSearch: true,
    width: '13.8%',
    disable: true,

    // ...getTextColumnProps('project'),
    // ...getDefaultValue('project'),
  },
  {
    title: t`State`,
    dataIndex: 'state',
    disable: true,
    // filterMultiple: false,
    hideInSearch: true,
    width: '5.7%',
    render: (_text, record, _, _action) => (
      <CenterWrapper>
        <StateIndicator id={String(record.id)} type={record.state} layout="compact"/>
      </CenterWrapper>
    ),
    ...sortOpts('state'),
  },
  {
    dataIndex: 'object_agenda',
    title: t`Agenda`,
    filters: true,
    valueType: 'text',
    hideInSearch: true,
    width: '9.5%',
    ...getTextColumnProps('object_agenda'),
    ...getDefaultValue('object_agenda'),
    ...sortOpts('object_agenda'),
  },
  {
    dataIndex: 'sender_module',
    title: t`Sender`,
    hideInSearch: true,
    filterMultiple: false,
    width: '10.5%',
    filters: filters['sender'],
    // [
    //   {
    //     text: 'Money',
    //     value: 'money',
    //   },
    //   {
    //     text: 'Pohoda',
    //     value: 'pohoda',
    //   },
    //   {
    //     text: 'Targito',
    //     value: 'targito',
    //   },
    // ],

    ...getDefaultValue('sender_module'),
    ...sortOpts('sender_module'),
  },
  {
    dataIndex: 'receiver_modules',
    title: t`Receivers`,
    hideInSearch: true,
    filterMultiple: false,
    width: '10.5%',
    filters: filters['receiver'],
    // [
    //   {
    //     text: 'Money',
    //     value: 'money',
    //   },
    //   {
    //     text: 'Pohoda',
    //     value: 'pohoda',
    //   },
    //   {
    //     text: 'Targito',
    //     value: 'targito',
    //   },
    // ],

    ...getDefaultValue('receiver_modules'),
    ...sortOpts('receiver_modules'),
    render: (_, record) => {
      const { receiver_modules } = record;
      const isArray = receiver_modules && Array.isArray(receiver_modules);
      return isArray ? receiver_modules.join(', ') : receiver_modules ? receiver_modules : '-';
    },
  },
  {
    dataIndex: 'attempts_number',
    title: t`Attempts`,
    hideInSearch: true,
    width: '4.7%',
    ...sortOpts('attempts_number'),
  },
  {
    dataIndex: 'accepted',
    title: t`Accepted`,
    hideInSearch: true,
    width: '5.5%',
    filterMultiple: false,

    filters: [
      {
        text: t`Yes`,
        value: 'true',
      },
      {
        text: t`No`,
        value: 'false',
      },
    ],

    ...getDefaultValue('accepted'),
    render: (_, record) => {
      const { accepted } = record;
      return (
        <CenterWrapper>
          <AcceptedCell accepted={accepted} />
        </CenterWrapper>
      );
    },
  },
  {
    dataIndex: 'time_created',
    title: t`Time created`,
    valueType: 'dateTime',
    hideInSearch: true,
    width: '10.5%',
    ...getDateColumnProps('time_created'),
    ...getTimeDefaultValue('time_created'),
    ...sortOpts('time_created'),

    render: (_, entity) => formatDate(entity.time_created, locale),
  },
  {
    dataIndex: 'time_processed',
    title: t`Time processed`,
    valueType: 'dateTime',
    hideInSearch: true,
    width: '10.5%',
    ...getDateColumnProps('time_processed'),
    ...getTimeDefaultValue('time_processed'),
    ...sortOpts('time_processed'),

    render: (_, entity) => formatDate(entity.time_processed, locale),
  },
  // {
  //   dataIndex: 'time_updated',
  //   title: t`Time updated`,
  //   valueType: 'dateTime',
  //   hideInSearch: true,
  //   width: 110,
  //
  //   ...getDateColumnProps('time_updated'),
  //   ...getTimeDefaultValue('time_updated'),
  //   ...sortOpts('time_updated'),
  //
  //   render: (_, entity) => formatDate(entity.time_updated, locale),
  // },
  // * uncomment if note in table row
  // {
  //   dataIndex: 'note_admin',
  //   title: t`Admin note`,
  //   hideInSearch: true,
  //   width: 250,

  //   render: (_, record) => {
  //     return <AdminNote id={String(record.id)} initialValue={record.note_admin} />;
  //   },
  // },
];

export { getColumns };
