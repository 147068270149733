import * as React from 'react';
import type { ActionType, ProFormInstance, ColumnsState } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import { InputRef, Space, Spin, Typography, Divider } from 'antd';
import type { TableProps } from 'antd';
import type { SorterResult } from 'antd/es/table/interface';
import { Trans, t } from '@lingui/macro';
import * as qs from 'query-string';

import type { QueueData, Row } from 'types';
// import { ALL_SITES_OPTION } from 'utils/constants';
import useDataSource from 'feature.home/hooks/useDataSource';
import { resources } from 'api';
import { get, post } from 'app/axiosSetup';
import Refresh from '../Refresh';
import RecordDescription from '../RecordDescription';
import { StateTabs } from '../StateIndicator';
import MassOperations from '../MassOperations';
import { useGlobalFiltersCtx } from 'context/GlobalFilters';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { ClearFilters, ColumnFilters } from './Filters';
import { getColumns } from './Schema';
import { ResponsiveSpace, StyleWrapper } from './style';
import { SelectedProvider, useSelectedAPI } from './context';
import Search from 'antd/lib/input/Search';
import { searchQueryAPI } from 'utils/searchQueryAPI';
import { useLingui } from '@lingui/react';
import { useInitialDataCtx, useReceivers, useSenders } from 'context/InitialData';
import { theme } from 'shared/theme';
import { CenterWrapper } from 'shared/generalStyles';
import ChildTable from './ChildTable';
import {
  useDefaultPage,
  scrollProps,
  rowClassNameProps,
  processFilters,
  processSorter,
  getInitialColFilters,
} from './shared';
import { StyledButton } from 'feature.home/styles';


const CustomTable = () => {
  const receiver = useReceivers();
  const sender = useSenders();

  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>([])
  const [uwsgiAction, setUwsgiAction] = React.useState<'start' | 'stop'>('start')
  const actionRef = React.useRef<ActionType>();
  const formRef = React.useRef<ProFormInstance>();
  const { organization, project } = useGlobalFiltersCtx();
  const firstRequest = React.useRef(true);

  const { i18n } = useLingui();

  const { setSelected } = useSelectedAPI();

  const [columnFilters, setColumnFilters] = React.useState<ColumnFilters>(getInitialColFilters());
  const ordering = React.useRef<{ ordering?: string | undefined }>({});
  const searchRef = React.useRef<InputRef>();
  const [columns, setColumns] = React.useState<any>(getColumns(i18n.locale, { receiver, sender }));

  const handleChange: TableProps<Row>['onChange'] = (
    _pagination,
    filters,
    sorter: SorterResult<Row>
  ) => {
    // * update filters
    setColumnFilters((columnFilters) => ({
      // state is being handled separately
      state: columnFilters.state,
      ...processFilters(filters),
    }));

    // * update sort
    ordering.current = processSorter(sorter);

    const newCols = getColumns(i18n.locale, { receiver, sender }).map((col) => {
      if (sorter.column) col.sortOrder = sorter.columnKey === col.dataIndex ? sorter.order : null;
      else col.sortOrder = null;
      return col;
    });
    setColumns(newCols);
  };

  const getUwsgiStatus = async () => {
    interface Parameters {
      project_name: string;
    };
    const parameters: Parameters = {
      project_name: project,
    };

    await get(resources.manageUwsgi, {
      params: parameters
    })
    .then((response) => {
      if (response['active']) {
        setUwsgiAction('stop')
      } else {
        setUwsgiAction('start')
      }
    })
    .catch((err) => {
      if (err.response.data !== "Can't manage UWSGI in debug mode") {
        throw err;
      }
    });
  }

  const setUwsgiStatus = async () => {
    interface Parameters {
      action: 'start' | 'stop',
      project_name: string
    }
    const parameters: Parameters = {
      action: uwsgiAction,
      project_name: project
    }

    await post(resources.manageUwsgi, {
      ...parameters
    })
    .then((response) => {
      setUwsgiAction(uwsgiAction === 'start' ? 'stop' : 'start')
    })
  }

  const handleRequest = async (
    params: {
      pageSize?: number;
      current?: number;
      keyword?: string;
    } = {}
  ) => {
    const originalParams = firstRequest.current ? qs.parse(window.location.search) : {};
    if (firstRequest.current) firstRequest.current = false;

    const organizationFilter = organization ? { organization } : { organization };
    const projectFilter = project ? { project } : { project };

    const searchValue = searchRef.current.input.value;
    const searchQuery = searchValue ? { search: searchValue } : {};

    const parameters = {
      ...originalParams,
      page: Number(params.current || 1),
      ...ordering.current,
      ...projectFilter,
      ...organizationFilter,
      ...columnFilters,
      ...searchQuery,
      descendant: false,
    };
    const stringified = qs.stringify(parameters);

    const currentURL = window.location.pathname;
    // empty table if no organization and project were selected
    if(!parameters.organization || !parameters.project){
      window.history.replaceState({}, '', `${currentURL}`);
      return {data: [], success: true, total: 0}
    }

    window.history.replaceState({}, '', `${currentURL}?${stringified}`);

    const response = await get<QueueData>(resources.queueData, {
      params: parameters,
    });

    alreadyFetchedData.current = response.results;

    let data = []
    data = response.results
    // delete records that have parent records
    data = data.filter(record => !record.has_parent)

    return { data: data, success: !!response, total: response.count };
  }

  const handleColumnsStateChange = (e: Record<string, ColumnsState>) => {
    let hiddenColumns = []
    Object.keys(e).forEach((dataIndex) => {
      if (e[dataIndex].show === false) {
        hiddenColumns.push(dataIndex)
      }
    })
    setHiddenColumns(hiddenColumns)
  }

  const optionsSettings = {
    setting: {
      listsHeight: 400,
    },
    fullScreen: false,
    reload: true,
    density: false,
  }

  const expandableSettings = {
    childrenColumnName: 'disable-expanded-row-renderer-error',
    expandedRowRender: (record) => (
      <>
      { record.ancestor && !record.descendant &&
        <>
          <ChildTable
            record={record}
            columns={columns.filter(column => !hiddenColumns.includes(column.dataIndex))}
          />
          <Divider />
        </>
      }
      <RecordDescription record={record} />
      </>
    )
    ,
    expandRowByClick: true,
    expandIcon: ({ expanded, onExpand, record }) =>
      expanded ? (
        <CaretUpOutlined onClick={(e) => onExpand(record, e)} />
      ) : (
        <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
      ),
    columnWidth: 20
  }

  const alreadyFetchedData = React.useRef<Row[]>(null);

  const reloadData = () => actionRef.current.reload();

  const { dataSource } = useDataSource(alreadyFetchedData.current, reloadData);

  const addProps = dataSource ? { dataSource } : {};

  useDefaultPage(actionRef);

  React.useEffect(() => {
    // initialize uwsgi status
    getUwsgiStatus()
    // eslint-disable-next-line
  }, []);

  const headerTitleRenderer = (
    <ResponsiveSpace>
      <MassOperations />
      <Space style={{ display: 'flex', alignItems: 'center' }}>
        <StateTabs
          value={columnFilters?.state || ''}
          onChange={(state) => {
            setColumnFilters((columnFilters) => ({
              ...columnFilters,
              state,
            }));
            formRef.current.submit();
          }}
        />
        <Search
          ref={searchRef}
          defaultValue={searchQueryAPI.getValue('search') || ''}
          placeholder={t`Search phrase`}
          onSearch={reloadData}
          enterButton
        />
        <StyledButton type={uwsgiAction === 'start' ? 'primary' : 'default'} onClick={setUwsgiStatus}>{uwsgiAction}</StyledButton>
      </Space>
    </ResponsiveSpace>
  )

  return (
    <StyleWrapper>
      <ProTable<Row>
        {...addProps}
        columns={columns}
        columnsState={{onChange: handleColumnsStateChange}}
        actionRef={actionRef}
        formRef={formRef}
        request={handleRequest}
        onChange={handleChange}
        editable={{type: 'multiple'}}
        rowClassName={rowClassNameProps}
        rowKey="id"
        search={{filterType: 'light'}}
        options={optionsSettings}
        size="small"
        headerTitle={headerTitleRenderer}
        rowSelection={{
          onChange(selectedRowKeys) {
            setSelected(selectedRowKeys);
          },
          alwaysShowAlert: false,
        }}
        tableAlertRender={false}
        pagination={{
          showSizeChanger: false,
          defaultPageSize: 100,
          showTotal: (total, range) => (
            <div>{t`showing ${range[0]}-${range[1]} of ${total} total items`}</div>
          ),
        }}
        expandable={expandableSettings}
        dateFormatter="string"
        toolBarRender={() => [
          <Refresh reloadData={reloadData} />,
          <ClearFilters
            columnFilters={columnFilters}
            ordering={ordering}
            search={searchRef}
            onClearFilters={() => {
              const newColumns = getColumns(i18n.locale, { receiver, sender }).map((col) => {
                col.filteredValue = null;
                col.sortOrder = null;
                return col;
              });

              setColumnFilters({});
              setColumns(newColumns);

              const currentURL = window.location.pathname;

              window.history.replaceState({}, '', `${currentURL}`);
            }}
          />,
        ]}
        scroll={scrollProps}
      />
    </StyleWrapper>
  );
};

const Table = () => {
  const { isLoading, isError } = useInitialDataCtx();

  if (isError) {
    return (
      <CenterWrapper>
        <Typography.Text style={{ color: theme.colors.error.main }}>
          <Trans>An unexpected error has occurred.</Trans>
        </Typography.Text>
      </CenterWrapper>
    );
  }

  if (isLoading) {
    return (
      <CenterWrapper>
        <Spin size="large" />
      </CenterWrapper>
    );
  }
  return (
    <SelectedProvider>
      <CustomTable />
    </SelectedProvider>
  );
};

export default Table;
