import { SET_AGENDA, SET_AGENDA_LIST, SET_STATS_TYPE } from './actions';
import { TDashboardCtx } from './types';

type TActionsModule = typeof import('./actions');
type TActions = TActionsModule[keyof TActionsModule];

export const dashboardReducer = (
  state: TDashboardCtx,
  action: { type: TActions; payload: any }
): TDashboardCtx => {
  switch (action.type) {
    case SET_AGENDA_LIST:
      return {
        ...state,
        agendaList: action.payload,
      };
    case SET_STATS_TYPE:
      return {
        ...state,
        statType: action.payload,
      };
    case SET_AGENDA:
      return {
        ...state,
        agenda: action.payload,
      };
    default:
      return state;
  }
};
