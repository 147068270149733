import React from 'react';

import { ConfigProvider } from 'antd';
import { createIntl, IntlProvider } from '@ant-design/pro-components';

import csCZIntl from 'antd/lib/locale/cs_CZ';
import skSKIntl from 'antd/lib/locale/sk_SK';
import enUSIntl from 'antd/lib/locale/en_US';
import { useLingui } from '@lingui/react';

const enLocale = {
  tableForm: {
    search: `Query`,
    reset: `Reset`,
    submit: `Submit`,
    collapsed: `Expand`,
    expand: `Collapse`,
    inputPlaceholder: `Please enter`,
    selectPlaceholder: `Please select`,
  },
  alert: {
    clear: `Clear`,
  },
  tableToolBar: {
    leftPin: `Pin to left`,
    rightPin: `Pin to right`,
    noPin: `Unpinned`,
    leftFixedTitle: `Fixed the left`,
    rightFixedTitle: `Fixed the right`,
    noFixedTitle: `Not Fixed`,
    reset: `Reset`,
    columnDisplay: `Column Display`,
    columnSetting: `Settings`,
    fullScreen: `Full Screen`,
    exitFullScreen: `Exit Full Screen`,
    reload: `Refresh`,
    density: `Density`,
    densityDefault: `Default`,
    densityLarger: `Larger`,
    densityMiddle: `Middle`,
    densitySmall: `Compact`,
  },
};

const csLocale = {
  tableForm: {
    search: `Dotaz`,
    reset: `Resetovat`,
    submit: `Potvrdit`,
    collapsed: `Rozšířit`,
    expand: `Sklapnout`,
    inputPlaceholder: `Prosím vpište`,
    selectPlaceholder: `Prosím vyberte`,
  },
  alert: {
    clear: `Vymazat`,
  },
  tableToolBar: {
    leftPin: `Připnout doleva`,
    rightPin: `Připnuto doprava`,
    noPin: `Odepnuto`,
    leftFixedTitle: `Připnout doleva`,
    rightFixedTitle: `Připnout doprava`,
    noFixedTitle: `Není Připnutý`,
    reset: `Resetovat`,
    columnDisplay: `Zobrazení sloupců`,
    columnSetting: `Nastavení`,
    fullScreen: `Celá obrazovka`,
    exitFullScreen: `Ukončete celou obrazovku`,
    reload: `Refresh`,
    density: `Hustota`,
    densityDefault: `Výchozí`,
    densityLarger: `Větší`,
    densityMiddle: `Střední`,
    densitySmall: `Kompaktní`,
  },
};

const skLocale = {
  tableForm: {
    search: `Dotaz`,
    reset: `Resetovať`,
    submit: `Potvrdiť`,
    collapsed: `Rozšíriť`,
    expand: `Zložiť`,
    inputPlaceholder: `Prosím vlož`,
    selectPlaceholder: `Prosím vyber`,
  },
  alert: {
    clear: `Vymazať`,
  },
  tableToolBar: {
    leftPin: `Pripnúť vľavo`,
    rightPin: `Pripnúť vpravo`,
    noPin: `Odopnuté`,
    leftFixedTitle: `Pripnite vľavo`,
    rightFixedTitle: `Pripnite vpravo`,
    noFixedTitle: `Nie je pripnuté`,
    reset: `Resetovať`,
    columnDisplay: `Zobrazenie stĺpcov`,
    columnSetting: `Nastavenia`,
    fullScreen: `Celá obrazovka`,
    exitFullScreen: `Ukončiť celú obrazovku`,
    reload: `Refresh`,
    density: `Hustota`,
    densityDefault: `Predvolené`,
    densityLarger: `Väčšie`,
    densityMiddle: `Stredný`,
    densitySmall: `Kompaktný`,
  },
};

const intlMap = {
  en: { ...enUSIntl, ...enLocale },
  cs: { ...csCZIntl, ...csLocale },
  sk: { ...skSKIntl, ...skLocale },
};

const csIntl = createIntl('cs_CZ', intlMap['cs']);
const skIntl = createIntl('sk_SK', intlMap['sk']);
const enIntl = createIntl('en_US', intlMap['en']);

const intl = {
  en: enIntl,
  sk: skIntl,
  cs: csIntl,
};

const AntLanguageProvider = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useLingui();
  const [lang, setLang] = React.useState(i18n.locale);

  React.useEffect(() => {
    setLang(i18n.locale);
  }, [i18n.locale]);

  return (
    <IntlProvider value={{ intl: intl[lang], valueTypeMap: {} }}>
      <ConfigProvider locale={intlMap[lang]}>{children}</ConfigProvider>
    </IntlProvider>
  );
};

export default AntLanguageProvider;
