import { useWebSocketCtx } from 'context/WebSocket';
import React from 'react';
import type { Row } from 'types';

const useDataSource = (alreadyFetchedData: Row[], reloadData: () => Promise<void>) => {
  const [dataSource, setDataSource] = React.useState<Row[]>(null);

  const { webSocket } = useWebSocketCtx();

  React.useEffect(() => {
    if (!webSocket) {
      setDataSource(null);
      reloadData();
      return;
    }

    webSocket.onmessage = (event: MessageEvent) => {
      const datum = JSON.parse(event.data);
      if (!alreadyFetchedData) return;
      if (!datum || datum.message) return;
      // TODO: make better general solution when adding new record

      const newData = [...alreadyFetchedData];
      newData.pop();
      newData.unshift({ ...datum, new_data: true } as Row);

      setDataSource(newData);
    };
  }, [alreadyFetchedData, dataSource, reloadData, webSocket]);

  return { dataSource };
};

export default useDataSource;
