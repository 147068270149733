import React, {useEffect} from 'react';
import { get } from 'app/axiosSetup';
import { Trans, t } from '@lingui/macro';
import { resources } from 'api';
import { useDashboardCtx, useDashboardAPICtx } from 'context/Dashboard';
import { DashboardWrapper } from './style';
import DailyLastMonth from './library/DailyLastMonth';
import MonthlyLastYear from './library/MonthlyLastYear';
import CalendarHeatmap from './library/CalendarHeatmap';
import { Form, Select, Space } from 'antd';
import { useGlobalFiltersCtx } from '../../context/GlobalFilters';
import { CenterWrapper } from 'shared/generalStyles';

function StatTypeSelector(){
  const {setStatType} = useDashboardAPICtx();

  const statTypesList = [
    {
      label: t`All`,
      value: 'all'
    },
    {
      label: t`Operations`,
      value: 'operation',
    },
    {
      label: t`Events`,
      value: 'event',
    }
  ];

  const optionElements = statTypesList.map((option) =>
    <Select.Option key={option.value} value={option.value}>
      {option.label}
    </Select.Option>
  );

  return (
    <Select
      defaultValue={'all'}
      onChange={value => setStatType(value)}
    >
      {optionElements}
    </Select>
  );
}

function AgendaSelector(){
  const {agendaList} = useDashboardCtx();
  const {setAgendaList, setAgenda} = useDashboardAPICtx();
  const {project} = useGlobalFiltersCtx();

  useEffect(() => {
    get(resources.statsAgendas, {params: {project}}).then((response: any) => {
      setAgendaList([
        {
          label: t`All`,
          value: ''
        },
        ...response.map(agenda => ({label: t`${agenda.object_agenda}`, value: agenda.object_agenda}))
      ])
    });
    // eslint-disable-next-line
  }, [project]);

  const optionElements = agendaList.map((option) =>
    <Select.Option key={option.value} value={option.value}>
        {option.label}
    </Select.Option>
  );

  return (
    <Select
      defaultValue={''}
      onChange={value => setAgenda(value)}
    >
      {optionElements}
    </Select>
  )

}

function Dashboard() {
  const {project, organization} = useGlobalFiltersCtx();

  if(!project || !organization)
    return (
      <DashboardWrapper>
        <CenterWrapper height={'calc(100vh - 110px)'}>
          <Trans>The organisation or project has not been selected</Trans>
        </CenterWrapper>
      </DashboardWrapper>
    )

  return (
    <DashboardWrapper>
      <div className='dashboard-header'>
        <Space>
          <Form.Item label={t`Type`}>
            <StatTypeSelector />
          </Form.Item>
          <Form.Item label={t`Agenda`}>
            <AgendaSelector />
          </Form.Item>
        </Space>
      </div>
      <DailyLastMonth />
      <MonthlyLastYear />
      <CalendarHeatmap />
    </DashboardWrapper>
  )
}

export default Dashboard
