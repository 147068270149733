import { theme } from './theme';

export const scrollbarStyles = `
    /* width */
      ::-webkit-scrollbar {
        height: 4px;
        border-radius: 2px;
        margin-top: -10px;
        width: 8px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: ${theme.colors.scrollBar.track};
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${theme.colors.scrollBar.thumb};
        border-radius: 4px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: ${theme.colors.scrollBar.thumbHover};
      }
      `;
