import * as React from 'react';

import { CheckOutlined } from '@ant-design/icons';
import { Button, Input, InputRef, Tooltip } from 'antd';
import { changeData } from 'feature.home/data/queue-data';
import { t } from '@lingui/macro';

const AdminNote = (props: { initialValue: string; id: string }) => {
  const { initialValue, id } = props;
  const inputRef = React.useRef<InputRef>(null);

  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const value = inputRef.current.input.value;

    try {
      setLoading(true);
      await changeData([id], { note_admin: value });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Input.Group compact size="small" style={{ display: 'inline-block' }}>
        <Input
          style={{ width: 'calc(100% - 24px)', maxWidth: '100%' }}
          defaultValue={initialValue}
          placeholder={t`Type a note`}
          allowClear
          ref={inputRef}
          disabled={loading}
          size="small"
        />
        <Tooltip title={t`Submit`}>
          <Button htmlType="submit" size="small" loading={loading} icon={<CheckOutlined />} />
        </Tooltip>
      </Input.Group>
    </form>
  );
};

export default AdminNote;
