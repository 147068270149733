import React from 'react';

import { useLocation } from 'react-router';
import { Menu } from 'antd';
import { HomeOutlined, PieChartOutlined, DatabaseOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';

const MenuContent = (props: { menuStyles?: { [key: string]: string } }) => {
  const routerLocation = useLocation();
  const { menuStyles = {} } = props;

  const menuItems = [
    {
      label: <Link to="/"><Trans>Dashboard</Trans></Link>,
      key: '/',
      icon: <HomeOutlined />
    },
    {
      label: <Link to="/transformations"><Trans>Transformations</Trans></Link>,
      key: '/transformations',
      icon: <DatabaseOutlined />
    },
    {
      label: <Link to="/reports"><Trans>Statistics</Trans></Link>,
      key: '/reports',
      icon: <PieChartOutlined />
    },
  ];

  return (
    <Menu
      theme="light"
      mode="inline"
      defaultSelectedKeys={[routerLocation.pathname]}
      style={{ height: '100%', ...menuStyles }}
      items={menuItems}
    />
  );
};

export default MenuContent;
