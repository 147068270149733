import { get } from 'app/axiosSetup';
import { t } from '@lingui/macro';
import { theme } from 'shared/theme';
import { resources } from 'api';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { ChartWrapper, WidgetWrapper } from 'feature.report/components/style';
import { format, subMonths, endOfMonth } from 'date-fns';
import { useLingui } from '@lingui/react';
import { useGlobalFiltersCtx } from 'context/GlobalFilters';
import { useDashboardCtx } from 'context/Dashboard';
import { dateFnsLocales, getMonths } from 'shared/dateFns';

const defaultOptions: EChartsOption = {
  title: {
    top: 0,
    left: 'center',
    text: t`Over the past year`
  },
  responsive: true,
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {
    top: 25
  },
  grid: {
    left: '6%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      data: [],
      axisLabel: {
        rotate: 50,
      },
    }
  ],
  yAxis: [
    {
      type: 'value'
    }
  ],
  series: []
};

const processServerData = (data: Array<any>, i18n) => {
  let series = [];
  const months: string[] = getMonths(
                {locale: dateFnsLocales[i18n.locale], dateFormat: 'M'})

  let groupByMonth = {};
  data.forEach((item) => {
    let month = format(new Date(item['time_since']), 'M');

    if(!groupByMonth[month]) groupByMonth[month] = {
      successfulCount: 0,
      failedCount: 0,
      timeSince: item['time_since']
    };

    groupByMonth[month].successfulCount += item['successful_count'];
    groupByMonth[month].failedCount += item['failed_count'];
  })

  let successfulSeriesData = [];
  let failedSeriesData = [];

  months.forEach((month) => {
    if(groupByMonth[month]){
      successfulSeriesData.push(groupByMonth[month].successfulCount);
      failedSeriesData.push(groupByMonth[month].failedCount);
    } else {
      successfulSeriesData.push(null);
      failedSeriesData.push(null);
    }
  });


  series.push({
    name: t`Successful`,
    type: 'bar',
    data: successfulSeriesData,
    itemStyle: {
      color: theme.colors.secondary.main
    },
    stack: 'stats',
  });

  series.push({
    name: t`Failed`,
    type: 'bar',
    data: failedSeriesData,
    itemStyle: {
      color: theme.colors.error.main
    },
    stack: 'stats',
  });

  return series;
}

function MonthlyLastWeek(){
  const {i18n} = useLingui();
  const {project, organization} = useGlobalFiltersCtx();
  const {agenda, statType} = useDashboardCtx();
  const statUrls = {
    'all': resources.statsAllMonthly,
    'operation': resources.statsOperationMonthly,
    'event': resources.statsEventMonthly,
  };

  const [chartOptions, setChartOptions] = useState<EChartsOption>(defaultOptions);
  const [chartIsReady, setChartIsReady] = useState<boolean>(false);


  useEffect(() => {
    if(agenda !== null){
      setChartIsReady(false)
      let params = {
        time_since__gte: subMonths(endOfMonth(new Date()), 12),
        time_since__lte: new Date(),
      }

      if(organization) params['company__company_name'] = organization
      if(project) params['project__name'] = project
      if(agenda) params['object_agenda'] = agenda

      get(statUrls[statType], {params: params})
        .then((response: object[]) => {
          let seriesData = processServerData(response, i18n);
          setChartOptions(prevState => {
            prevState.title.text = t`Over the past year`;
            prevState.series = seriesData;
            prevState.xAxis[0].data = getMonths(
                {locale: dateFnsLocales[i18n.locale], dateFormat: 'MMMM'});
            return prevState;
          })
          setTimeout(() => setChartIsReady(true), 1000);
        })
    }
    // eslint-disable-next-line
  }, [organization, project, agenda, i18n, statType]);

  return (
    <WidgetWrapper>
      {
        chartIsReady
        ? <ChartWrapper><ReactECharts option={chartOptions} /></ChartWrapper>
          : <ChartWrapper><Spin/></ChartWrapper>
      }
    </WidgetWrapper>
  )
}

export default MonthlyLastWeek;
