import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Login from 'pages/Login';
import AppHome from 'pages/Home';
import Report from 'pages/Report';
import Transformation from 'pages/Transformation';

import Page from 'app/Page';
import PrivateRoute from 'app/PrivateRoute';
import { LinguiProvider } from 'app/lingui';
import AntLanguageProvider from 'app/AntLanguageProvider';
import QueryProvider from 'app/QueryProvider';

import { MessageProvider } from 'context/Message';
import { AuthProvider } from 'context/Auth';
import { WebSocketProvider } from 'context/WebSocket';
import { IntervalProvider } from 'context/Refresh';

import './App.less';
import { GlobalFiltersProvider } from 'context/GlobalFilters';
import { InitialDataProvider } from 'context/InitialData';

function App() {
  return (
    <LinguiProvider>
      <QueryProvider>
        <Router>
          <AntLanguageProvider>
            <IntervalProvider>
              <WebSocketProvider>
                <MessageProvider>
                  <GlobalFiltersProvider>
                    <AuthProvider>
                        <InitialDataProvider>
                          <div className="App">
                            <div className="App-body">
                              <Page>
                                <Switch>
                                  <Route exact path="/login" component={Login} />
                                  <PrivateRoute exact path="/reports" component={Report} />
                                  <PrivateRoute exact path="/transformations" component={Transformation} />
                                  <PrivateRoute path="/" component={AppHome} />
                                </Switch>
                              </Page>
                            </div>
                          </div>
                        </InitialDataProvider>
                    </AuthProvider>
                  </GlobalFiltersProvider>
                </MessageProvider>
              </WebSocketProvider>
            </IntervalProvider>
          </AntLanguageProvider>
        </Router>
      </QueryProvider>
    </LinguiProvider>
  );
}

export default App;
