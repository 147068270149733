import React from 'react';
import { Button, Select, Space } from 'antd';
import { Trans } from '@lingui/macro';
import { changeData } from 'feature.home/data/queue-data';
import { useMessageCtx } from 'context/Message';
import { useSelected } from './Table/context';
import type { QueueRecordState } from 'types';

type TOperation = { state?: QueueRecordState };
type TValue = 'processed' | 'processing';

const MassOperations = () => {
  const { selected } = useSelected();
  const { setMessage } = useMessageCtx();
  const valueRef = React.useRef<TValue>('processed');

  const onSubmit = async () => {
    const value = valueRef.current;

    if (!value || !selected) {
      return;
    }

    const selectedIds = selected.map((id) => String(id));

    const operation: TOperation = { state: value };

    const { message, type } = await changeData<TOperation>(selectedIds, operation);
    setMessage(type, message);
  };

  return (
    <Space>
      <Select<TValue>
        id="mass-operations"
        defaultValue={'processed'}
        style={{ minWidth: '200px' }}
        onChange={(value) => (valueRef.current = value)}
      >
        <Select.Option value={'processed'}>
          <Trans>Mark as 'processed'</Trans>
        </Select.Option>
        <Select.Option value={'processing'}>
          <Trans>Process marked again</Trans>
        </Select.Option>
      </Select>
      <Button type="primary" disabled={!selected.length} onClick={onSubmit}>
        <Trans>Apply</Trans>
      </Button>
    </Space>
  );
};

export default MassOperations;
